import React, { Component } from 'react';
import SubCabecalho from '../genericos/subcabecalho.js';
import {
  Grid,
  Form,
  Segment,
  Loader,
  Message
} from 'semantic-ui-react';
import { getKeyUsuario, getToken } from '../../servicos/validatoken.js';

class AtualizaDadosCadastrais extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      senha: '',
      exibeMensagem: '',
      corMensagem: ''
    };
    this.alterarSenha = this.alterarSenha.bind(this);
  }
  
  alterarSenha(){
    this.setState({ Loader: true });
    const { senha } = this.state;
    if (!senha) {
      this.setState({ exibeMensagem: 'Preencha o campo Senha!', corMensagem: "red", Loader: false });
    } else {
      const request = require('request'),
            opcoes = {
              method: 'PUT',
              url:'https://inteligenciaindustrial.com/engind/usuario',
              headers: {
                'content-type': 'application/json',
                'x-access-token': getToken()
              },
              body: { 'key': getKeyUsuario(), 'senha': senha },
              json: true
            };
      request(opcoes, function (error, response, body) {
        if (body !== undefined) {
          if (body.length > 0) {
            this.setState({ exibeMensagem: "Senha alterada com sucesso", corMensagem: "green", Loader: false });
          } else {
            this.setState({ exibeMensagem: "Não foi possivel alterar sua senha!", corMensagem: "red", Loader: false });
          }
        } else {
          this.setState({ exibeMensagem: "Não foi possivel conectar-se a internet tente novamente", corMensagem: "red" , 
                          Loader: false });
        }
      }.bind(this));
    }
  }
  
  render(){
    return(
      <div>
        <SubCabecalho tituloTela = "ALTERAR SENHA"
                      opcaoTela = {'alterarSenha'}/>
        <Grid style={{ height: '100%', marginLeft: '0px', marginRight: '0px' }}>
          <Grid.Row textAlign='center' >
            <Grid.Column width = {16}>
              <Form size='large' >
                <Segment stacked>
                  <Form.Input fluid icon='lock' iconPosition='left' placeholder='Nova Senha' type="password" 
                              name = "password" 
                              onChange = { e => this.setState({ senha: e.target.value })}/>
                  { this.state.Loader && <Loader active /> }
                  <button class="ui black large fluid icon right labeled button" onClick = { this.alterarSenha }> SALVAR
                    <i aria-hidden="true" class="save outline"></i>
                  </button>
                </Segment>
              </Form>
              {
                this.state.exibeMensagem && 
                <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default AtualizaDadosCadastrais;