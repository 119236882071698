import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import {Loader, Message, Icon, Grid, Header, Segment} from 'semantic-ui-react';

class RenderizaPasta extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      exibeMensagem: '',
      corMensagem: '',
      PastasRenderizar: [],
      ArquivosFiltrados: null,
      posicaoCaminho: 0,
      ArquivosFiltradosArea: [],
      PastasFiltradas: [],
      nomePastaAnterior: '',      
      posUltimaPasta: 0,
      nomePastaAtual: '',
      caminhoPastas: '',
      Pastas: [],
      indexPasta: 0,
      ArrayNavegacaoPastas: [],
      ArrayArquivos: [],
      indexPastaSelecionada: 0,
      //
      Arquivos: [],
      ComponentePastas: [],
    };
    //this.controlaExibicaoPasta = this.controlaExibicaoPasta.bind(this);
    this.controlaPasta = this.controlaPasta.bind(this);
  }
  //
  componentDidMount() {
    this.setState({Loader: true});
    let codigoArea = '';
    //define o código de cada area que foi passado como propriedade
    switch(this.props.nomeArea) {
      case 'PROJETOS CIVIS':
        codigoArea = 'ECI';
        break;
      case 'PROJETOS ELÉTRICOS':
        codigoArea = 'EEL';
        break;
      case 'PROJETOS MECÂNICOS':
        codigoArea = 'EME';
        break;
      case 'DOCUMENTAÇÃO LEGAL':
        codigoArea = 'DLE';
        break;
      case 'CRONOGRAMA FÍSICO':
        codigoArea = 'CFI';
        break;
      default:
        codigoArea = ''
    }
    //filtra os projetos 
    const ProjetoFiltrado =  this.props.projeto.filter(projeto => {
      return projeto.key === this.props.idProjeto
    });
    const codigoProjeto = ProjetoFiltrado[0].codigo_projeto;
    //definição dos parametros para a requisição na api de controle de arquivos
    const request = require('request');
    const opcoesArquivosProjetos = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/arquivos/filtrado',
      headers: {
        'content-type': 'application/json'
      },
      body: { 
        usuario: 'engind', senha: 'engind@123', codigoProjeto: codigoProjeto, codigoArea: codigoArea
      },
      json: true
    }
    request(opcoesArquivosProjetos, function (error, response, body) {
      let Arquivos = [];
      if (body.length > 0) {
        Arquivos = body.sort(this.ordena);
        Arquivos.forEach(function(arquivo){
          arquivo.caminho_arquivo =  arquivo.path.split("-");
        })
        this.setState({
          Arquivos: Arquivos, Loader: false
        });
        this.controlaPasta();
      } else {
        //ERRO AO REALIZAR A CONSULTA DOS ARQUIVOS
        this.setState({Loader: false, exibeMensagem: `Nenhum registro de ${this.props.nomeArea.toLocaleLowerCase()} disponibilizado`, 
                       corMensagem: "orange" });
      }
    }.bind(this));
  }
  
  //FUNÇÃO QUE ORDENA O PATH DOS ARQUIVOS
  ordena(a,b) {
    if (a.path < b.path)
      return -1;
    if (a.path > b.path)
      return 1;
    return 0;
  }
  
  //FUNÇÃO QUE FAZ A NAVEÇÃO ENTRA AS PASTAS
  controlaPasta(){
    this.validaLocalStorage();
    let { Arquivos } = this.state;
    let indexPasta = window.localStorage.getItem('indexPasta');
    let CaminhoPastas = JSON.parse(window.localStorage.getItem('CaminhoPastas'));
    let ComponentePastas = [], PastaRenderizadas = [], exibirArquivos = false;
    for (var i = 0; i < Arquivos.length; i++) {
      //CASO NÃO SEJA A PRIMEIRA PASTA REALIZA A COMPARAÇÃO DAS PASTAS
        if (CaminhoPastas.length > 0) {
          let caminhoValido = true, j = 0;
          CaminhoPastas.forEach(function(pasta){
            if (Arquivos[i].caminho_arquivo[j] !== pasta) {
              caminhoValido = false
            }
            j++
          });
          if (caminhoValido) {
            //VERIFICA SE EXISTE PROXIMA PASTA CASO NÃO CHAMA O COMPONENTE PARA EXIBIR OS ARQUIVOS
            if (Arquivos[i].caminho_arquivo[indexPasta] !== undefined) {
              if (PastaRenderizadas.indexOf(Arquivos[i].caminho_arquivo[indexPasta]) === -1) {
                PastaRenderizadas.push(Arquivos[i].caminho_arquivo[indexPasta]);
                let nomePasta = Arquivos[i].caminho_arquivo[indexPasta]
                ComponentePastas.push(
                  <Grid.Row key = {Arquivos[i].key} 
                            style = {{ paddingBottom: '2px', paddingTop: '2px', paddingRight: '0px', paddingLeft: '10px'}}
                            onClick = {() => this.proximaPasta(nomePasta)}>
                    <Grid.Column style = {{width:'10%', margin: 'auto'}}>
                      <Icon link name='folder' size='big'/>
                    </Grid.Column>
                    <Grid.Column style = {{width:'90%', cursor: 'pointer'}}>
                      {Arquivos[i].caminho_arquivo[indexPasta]}
                    </Grid.Column>
                  </Grid.Row>
                );
              }
            } else {
              exibirArquivos = true
              break;
            }
          }
        } else {
          if (PastaRenderizadas.indexOf(Arquivos[i].caminho_arquivo[indexPasta]) === -1) {
            PastaRenderizadas.push(Arquivos[i].caminho_arquivo[indexPasta]);
            let nomePasta = Arquivos[i].caminho_arquivo[indexPasta]
            ComponentePastas.push(
              <Grid.Row key = {Arquivos[i].key} 
                        style = {{ paddingBottom: '2px', paddingTop: '2px', paddingRight: '0px', paddingLeft: '10px'}}
                        onClick = {() => this.proximaPasta(nomePasta)}>
                <Grid.Column style = {{width:'10%', margin: 'auto'}}>
                  <Icon link name='folder' size='big'/>
                </Grid.Column>
                <Grid.Column style = {{width:'90%', cursor: 'pointer'}}>
                  {Arquivos[i].caminho_arquivo[indexPasta]}
                </Grid.Column>
              </Grid.Row>
            );
          }
        }
    }
    if (exibirArquivos) {
      let caminhoPrincipal = '', pos = 1;
      CaminhoPastas.forEach(function(caminho){
        if (CaminhoPastas.length === pos) {
          caminhoPrincipal += caminho
        } else {
          caminhoPrincipal += caminho + "-"
        }
        pos++;
      });
      indexPasta--
      CaminhoPastas.pop();
      window.localStorage.setItem('indexPasta', indexPasta);
      window.localStorage.setItem('CaminhoPastas', JSON.stringify(CaminhoPastas));
      PubSub.publish('caminhoArquivos', { caminhoArquivos: caminhoPrincipal });
      PubSub.publish('opcaoComponente', { opcaoComponente:'listaArquivosProjeto' });
    } else {
      
    }
    this.setState({
      ComponentePastas: ComponentePastas
    });
  }
  
  //FUNÇÃO QUE VERIFICA SE AS VARIAVEIS DO LOCALSTORAGE SÃO VALIDAS
  validaLocalStorage(){
    if (window.localStorage.getItem('indexPasta') === null) {
     window.localStorage.setItem('indexPasta', 0);
    }
    if (window.localStorage.getItem('CaminhoPastas') === null) {
      window.localStorage.setItem('CaminhoPastas', JSON.stringify([]))
    }
  }

  //
  proximaPasta(nomePasta){
    let indexPasta = window.localStorage.getItem('indexPasta');
    let CaminhoPastas = JSON.parse(window.localStorage.getItem('CaminhoPastas'));
    indexPasta++
    CaminhoPastas.push(nomePasta);
    window.localStorage.setItem('indexPasta', indexPasta);
    window.localStorage.setItem('CaminhoPastas', JSON.stringify(CaminhoPastas));
    this.controlaPasta();
  }

//
  voltar(){
    let CaminhoPastas = JSON.parse(window.localStorage.getItem('CaminhoPastas')),
        indexPasta = window.localStorage.getItem('indexPasta');
    if (CaminhoPastas.length > 0) {
      indexPasta--;
      CaminhoPastas.pop();
      window.localStorage.setItem('indexPasta', indexPasta);
      window.localStorage.setItem('CaminhoPastas', JSON.stringify(CaminhoPastas));
      this.controlaPasta();     
    } else {
      PubSub.publish('opcaoComponente', { opcaoComponente:'carregarProjeto' });
    }
  }
  //
  render() {
    return(
      <div>
        <Grid style={{ height: '100%'}}>
          <Grid.Row textAlign='center' >
            <Grid.Column width = {2} ></Grid.Column>
            <Grid.Column width = {12} style = {{paddingTop: '0px'}}>
              <Header as = 'h5'>{this.props.descricao_geral + ' - ' + this.props.nomeArea}</Header>
            </Grid.Column>
            <Grid.Column width = {2}>
              {
                this.props.opcaoTela !== 'fabrica' && 
                <Icon floated='right' link name="reply"  style = {{paddingTop: '0px'}} 
                      onClick = {() => this.voltar()}/>
              }
              <br/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{paddingTop: '10px'}}>
          </Grid.Row>
        </Grid>
        {
          this.state.exibeMensagem && 
          <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
        }
        { this.state.ComponentePastas.length > 0 &&
          <Segment style = {{fontSize:'12px', paddingTop: '20px', paddingLeft: '0px', paddingRight: '0px' ,
                           height: '480px', width:'100%', overflowY: 'auto',  overflowX: 'hidden'}}>
            <Grid columns={2}>
              {
                this.state.ComponentePastas
              }
            </Grid>
          </Segment>
        }
        
        { this.state.Loader && 
          <Loader active inline='centered'/> 
        }
      </div>
    );
  }
}
//
export default RenderizaPasta;