import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import SubCabecalho from '../genericos/subcabecalho.js';
import { ReactTabulator } from 'react-tabulator';
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css";
import { Checkbox, Loader, Message, Select, Grid, Segment, Input, Button} from 'semantic-ui-react';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';

class BuscaArquivos extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      DataExibicao: [],
      projetos: [],
      areas: [],
      arquivos:[],
      arquivosFiltrados: [],
      columns:[],
      opcoesExtensao: [],
      mostrarArquivosAntigos: false,
      loader: true,
      exibeMensagem: '',
      corMensagem: '',
      unidadeFabrisSelect: [],
      filtro: ''
      
    };
    this.controleExibicaoArquivos = this.controleExibicaoArquivos.bind(this);
    this.atualizaFiltro = this.atualizaFiltro.bind(this);
  }
  
  componentDidMount() {
    let unidadeFabril = [];
    if (this.props.usuario.filial !== "CORP") {
      let filial = this.props.usuario[0].filial;
      unidadeFabril = this.props.unidadesFabril.filter(function (value) {
        return value.descricao_unidade_fabril === filial
      })
    }
    
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    const request = require('request');
    const opcoesAcao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acaousuario',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        data_acao: dataFormatada, hora_acao: horaFormatada, nome_tela : "BUSCA DE ARQUIVOS", codigo_projeto: "", 
        codigo_unidade_fabril: "", codigo_controle_versao: codigoVersao, key_usuario: getKeyUsuario()
      },
      json: true
    };
    const opcoesProjetos = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/engind/projeto',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    };
    const getAreas = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/engind/area',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    };
    const getArquivos = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/arquivos/buscaarquivosfilial',
      headers: {
        'content-type': 'application/json'
      },
      body: { 
        usuario: 'engind', senha: 'engind@123', unidadeFabril: unidadeFabril
      },
      json: true
    };
    request(opcoesAcao, function (error, response, body) {
      if (body.mensagem !== 'token inválido') {
        request(opcoesProjetos, function (error, response, body) {
          let Projetos = [];
          if (body !== undefined) {
            Projetos = body;
            request(getAreas, function (error, response, body) {
              let Areas = [];
              if (body !== undefined) {
                Areas = body
                request(getArquivos, function (error, response, body) {
                  const Data = [];
                  if (body !== undefined) {
                    body.map(item => {
                      let nomeArquivo = '';
                      let nomeArea = '';
                      let nomeProjeto = '';
                      let Projeto = Projetos.filter(registro => {
                        return registro.codigo_projeto === item.codigo_projeto
                      });
                      let Area = Areas.filter(registro => {
                        return registro.codigo_area === item.area
                      });
                      if (item.nome_original === null || item.nome_original === 'null') {
                          item.nome_original = ""
                        }
                        
                        if (item.detalhes_arquivo === null || item.detalhes_arquivo === 'null') {
                          item.detalhes_arquivo = ""
                        }
                      let ArrayPath = item.path.split('-');
                      if (ArrayPath.indexOf('VERSÕES ATUAIS') > -1) {
                        let nomeOriginal = '', detalhesArquivo = '';
                        for (let index = 6; index < item.nome_arquivo.split("-").length; index++) {
                          if (index < item.nome_arquivo.split("-").length-1) {
                            nomeArquivo += item.nome_arquivo.split("-")[index] + '-';
                          } else {
                            nomeArquivo += item.nome_arquivo.split("-")[index];
                          }
                        }
                        
                        if (Projeto.length !== 0) {
                          nomeProjeto = Projeto[0].descricao_projeto
                        }
                        if (Area.length !== 0) {
                          nomeArea = Area[0].abreviatura_area
                        }
                        
                        if (item.nome_original === null || item.nome_original === 'null') {
                          nomeOriginal = ''
                        } else {
                          nomeOriginal = item.nome_original
                        }
                        
                        if (item.detalhes_arquivo === null || item.detalhes_arquivo === 'null') {
                          detalhesArquivo = ''
                        } else {
                          detalhesArquivo = item.detalhes_arquivo
                        }
                        
                        
                        Data.push({'id': item.id, 'key':item.key, 'nome_arquivo': nomeArquivo,
                                   'codigo_projeto': nomeProjeto, 'area': nomeArea, 'path': item.path, 'nome_original': nomeOriginal, 
                                    'detalhes_arquivo' : detalhesArquivo, 'createdAt': item.createdAt});
                      }
                    });
                    this.setState({
                      data: Data, DataExibicao: Data, arquivos: body, arquivosFiltrados: body, projetos: Projetos, areas: Areas, loader: false
                    });
                  }
                }.bind(this))
              }
            }.bind(this))
          }
        }.bind(this))
      } else {
        this.setState({ exibeMensagem: 'Token expirou faço o acesso novamente!', corMensagem: "orange",  Loader: false });
        setTimeout(function() {
          PubSub.publish('autenticacao', { validado:false });
        }, 6000);
      }
    }.bind(this))
 
  }
  
  rowClick(e, row){
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '-' + mesF + '-' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    //hora,dia,minutos,ano,mes
    const token = Math.random() + '-' + horaF + '-' + Math.random() + '-' + diaF + '-' + Math.random() + '-' + minutosF + '-' + Math.random() + '-' + anoF + '-' + Math.random() + '-' + mesF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    if (browser.name === 'ios') {
      window.location = "googlechrome-x-callback://inteligenciaindustrial.com/engind/arquivos/downloadd/" + row._row.data.key + "&" + token + "&" + dataFormatada
                + "&" + horaFormatada + "&" + codigoVersao + "&" + getKeyUsuario() + "&" + row._row.data.nome_arquivo;
    } else {
      window.location = "https://inteligenciaindustrial.com/engind/arquivos/downloadd/" + row._row.data.key + "&" + token + "&" + dataFormatada
                + "&" + horaFormatada + "&" + codigoVersao + "&" + getKeyUsuario() + "&" + row._row.data.nome_arquivo;
    }
  }
  
  controleExibicaoArquivos(event){
    this.setState({loader: true});
    let { mostrarArquivosAntigos, projetos, areas, arquivos } = this.state;
    let Data = [];
    let nomeArea = '';
    let nomeProjeto = '';
    if (!mostrarArquivosAntigos) {
      arquivos.map(item => {
        let nomeArquivo = '';
        let Projeto = projetos.filter(registro => {
          return registro.codigo_projeto === item.codigo_projeto
        });
        let Area = areas.filter(registro => {
          return registro.codigo_area === item.area
        });
        let ArrayPath = item.path.split('-');
        for (let index = 6; index < item.nome_arquivo.split("-").length; index++) {
          if (index < item.nome_arquivo.split("-").length-1) {
            nomeArquivo += item.nome_arquivo.split("-")[index] + '-';
          } else {
            nomeArquivo += item.nome_arquivo.split("-")[index];
          }
        }
        if (Projeto.length !== 0) {
          nomeProjeto = Projeto[0].descricao_projeto
        }
        if (Area.length !== 0) {
          nomeArea = Area[0].abreviatura_area
        }
        Data.push({'id': item.id, 'key':item.key, 'nome_arquivo': nomeArquivo,
                   'codigo_projeto': nomeProjeto, 'area': nomeArea, 'path': item.path, 'nome_original': item.nome_original, 
                   'detalhes_arquivo': item.detalhes_arquivo, 'createdAt': item.createdAt});
      });
    } else {
      arquivos.map(item => {
        let nomeArquivo = '';
        let Projeto = projetos.filter(registro => {
          return registro.codigo_projeto === item.codigo_projeto
        });
        let Area = areas.filter(registro => {
          return registro.codigo_area === item.area
        });
        let ArrayPath = item.path.split('-');
        if (ArrayPath.indexOf('VERSÕES ATUAIS') > -1) {
          for (let index = 6; index < item.nome_arquivo.split("-").length; index++) {
            if (index < item.nome_arquivo.split("-").length-1) {
              nomeArquivo += item.nome_arquivo.split("-")[index] + '-';
            } else {
              nomeArquivo += item.nome_arquivo.split("-")[index];
            }
          }
          if (Projeto.length !== 0) {
            nomeProjeto = Projeto[0].descricao_projeto
          }
          if (Area.length !== 0) {
            nomeArea = Area[0].abreviatura_area
          }
          Data.push({'id': item.id, 'key':item.key, 'nome_arquivo': nomeArquivo,
                     'codigo_projeto': nomeProjeto, 'area': nomeArea, 'path': item.path, 'nome_original': item.nome_original,
                     'detalhes_arquivo': item.detalhes_arquivo, 'createdAt': item.createdAt});
        }
      });
    }
    this.setState({
      mostrarArquivosAntigos: mostrarArquivosAntigos === true ? false : true, data: Data, DataExibicao: Data, loader: false
    });
  }
  
  alteraSelect(x, y){
    const { arquivos } = this.state;
    let arquivosFiltrados = []
    if (y.value !== 'todas') {
      arquivosFiltrados = arquivos.filter(function (value) {
        return value.nome_arquivo.split("-")[0] === y.value
      });
    } else {
      arquivosFiltrados = arquivos
    }
    
    let { mostrarArquivosAntigos, projetos, areas } = this.state;
    let Data = [];
    let nomeArea = '';
    let nomeProjeto = '';
    
    arquivosFiltrados.map(item => {
        let nomeArquivo = '';
        let Projeto = projetos.filter(registro => {
          return registro.codigo_projeto === item.codigo_projeto
        });
        let Area = areas.filter(registro => {
          return registro.codigo_area === item.area
        });
        let ArrayPath = item.path.split('-');
        if (ArrayPath.indexOf('VERSÕES ATUAIS') > -1) {
          for (let index = 6; index < item.nome_arquivo.split("-").length; index++) {
            if (index < item.nome_arquivo.split("-").length-1) {
              nomeArquivo += item.nome_arquivo.split("-")[index] + '-';
            } else {
              nomeArquivo += item.nome_arquivo.split("-")[index];
            }
          }
          if (Projeto.length !== 0) {
            nomeProjeto = Projeto[0].descricao_projeto
          }
          if (Area.length !== 0) {
            nomeArea = Area[0].abreviatura_area
          }
          Data.push({'id': item.id, 'key':item.key, 'nome_arquivo': nomeArquivo,
                     'codigo_projeto': nomeProjeto, 'area': nomeArea, 'path': item.path, 'nome_original': item.nome_original,
                     'detalhes_arquivo': item.detalhes_arquivo, 'createdAt': item.createdAt});
        }
      });
    
    
    this.setState({
      arquivosFiltrados: arquivosFiltrados, data: Data, DataExibicao: Data, mostrarArquivosAntigos: false
    });
    
  }
  
  atualizaFiltro (event){
    this.pesquisar(event.target.value.toUpperCase())
    //this.setState({filtro: event.target.value});
  }
  
  pesquisar(filtro){
    const { data, DataExibicao } = this.state;
    let novoData = [], parametros = filtro.split(" ");
    
    data.forEach(function (value) {
      let parametrosValidos = false;
      for (var index = 0; index < parametros.length; index++) {
        if (value.nome_arquivo.match(parametros[index]) ||
             value.codigo_projeto.match(parametros[index]) ||
             value.area.match(parametros[index]) ||
             value.path.match(parametros[index]) ||
             value.nome_original.match(parametros[index]) ||
             value.detalhes_arquivo.match(parametros[index])
        ) {
          //Vai para o proximo parammetro
          parametrosValidos = true
        } else {
          parametrosValidos = false
          break;
        }
      }
      
      if (parametrosValidos) {
        novoData.push(value)
      }
    });
    
    this.setState({
      DataExibicao: novoData
    });
    
    
  }
  
  render(){
    let Options = {}, columns = [];
    let dataHoje = new Date();
    var dataOntem = new Date(dataHoje.getTime());
    dataOntem.setDate(dataHoje.getDate() - 1);
    columns = [
      {
        formatter:function responsiveCollapse(cell, formatterParams, onRendered) {
          var self = this, open = false, el = document.createElement("div");
          
          if (document.getElementsByClassName('tabulator-responsive-collapse-toggle')[document.getElementsByClassName('tabulator-responsive-collapse-toggle').length-1] !== undefined) {
            let elemento = document.getElementsByClassName('tabulator-responsive-collapse-toggle')[document.getElementsByClassName('tabulator-responsive-collapse-toggle').length-1];
            let cellData = new Date(cell._cell.row.data.createdAt);
            if(dataOntem.getDate() <= cellData.getDate() && dataOntem.getMonth() <= cellData.getMonth() && dataOntem.getFullYear() <= cellData.getFullYear()){
              elemento.style.backgroundColor = '#ea5c0a'
            }
           // console.log(cell._cell.row.data.createdAt);
          }
          
          function toggleList(isOpen) {
            var collapse = cell.getRow().getElement().getElementsByClassName("tabulator-responsive-collapse")[0];
            open = isOpen;
            if (open) {
              el.classList.add("open");
              if (collapse) {
                collapse.style.display = '';
              }
            } else {
              el.classList.remove("open");
              if (collapse) {
                collapse.style.display = 'none';
              }
            }
          }
          
          el.classList.add("tabulator-responsive-collapse-toggle");
          el.innerHTML = "<span class='tabulator-responsive-collapse-toggle-open' >+</span><span class='tabulator-responsive-collapse-toggle-close'>-</span>";
          cell.getElement().classList.add("tabulator-row-handle");
          if (self.table.options.responsiveLayoutCollapseStartOpen) {
            open = true;
          }
          el.addEventListener("click", function (e) {
            e.stopImmediatePropagation();
            toggleList(!open);
          });
          toggleList(open);
          return el;
        }, width:30, minWidth:30, align:"center", headerSort:false
      },
      {
        title: "PROJETO", 
        field: "codigo_projeto",
        align: "center",
        minWidth: 100, 
        responsive:1,
      },
      {
        title: "ÁREA", 
        field: "area",
        align: "center",
        width : 85,
        resizable:false
      },
      {
        title: "PASTA", 
        field: "path", 
        align: "center",
        //width: 120
        minWidth : 450
      },
      { 
        title: "NOME ORIGINAL", 
        field: "nome_original", 
        width : 700,
        responsive: 2,
        resizable:false
      },
      { 
        title: "ARQUIVO", 
        field: "nome_arquivo", 
        minWidth : 180,
        responsive:0,
        resizable:false
      },
      {
        title: "DETALHES ARQUIVO",
        field: "detalhes_arquivo",
        width : 300,
        responsive: 2,
        resizable: false
      }
    ];
    //Options = {responsiveLayout: 'hide'}
    Options = {responsiveLayout:'collapse', responsiveLayoutCollapseStartOpen:false }
    //Options = {responsiveLayout:true, groupBy:["codigo_projeto","area","path"], groupStartOpen:[false, false, false]}
    return(
      <div style = {{maxWidth: '95%', margin: 'auto', fontSize:'9px'}}>
        <SubCabecalho style={{paddingBottom: '0px'}}tituloTela = { "BUSCA DE ARQUIVOS" } 
                      opcaoTela = {'buscaArquivos'} />
          <Checkbox style= {{paddingBottom: '15px'}} label='Mostrar arquivos antigos' checked={this.state.mostrarArquivosAntigos} onClick = {this.controleExibicaoArquivos}/>
          { this.props.modoExibicao !== "buscaArquivoLista" &&
              <Input size='big' fluid icon='search' placeholder='Pesquisar...'
                       onChange = {this.atualizaFiltro}
                       style = {{ marginBottom: '20px' }} />
          }

          {this.state.loader && <Loader active />}
          {
            this.state.exibeMensagem && 
            <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
          }
          <ReactTabulator
            data={this.state.DataExibicao}
            style={{margin:'0px', fontSize:'9px', height: '480px' }}
            className={'very compact celled'}
            options= {Options}
            layout={"fitData"}
            columns={columns}
            rowClick={this.rowClick}
           />
      </div>
    );
  }
}

export default BuscaArquivos;

function filtroCustomizado(headerValue, rowValue, rowData, filterParams){

    let headerValueArr = headerValue.split(" ");
    
    let p = '';
    
    for(var x = 0; x < headerValueArr.length; x++){
        
        if(x<headerValueArr.length-1){
             p = p + 'rowValue.toUpperCase().match("'+headerValueArr[x]+'".toUpperCase()) && ';    
        }else{
            p = p + 'rowValue.toUpperCase().match("'+headerValueArr[x]+'".toUpperCase()) ';
        }
        
    }

    return eval(p) ; 
    
}
