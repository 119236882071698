import React, { Component } from 'react';
import Main from './componentes/main.js';

class App extends Component {
  render() {
    return (
     <Main/>      
    );
  }
}

export default App;
