import React, { Component } from 'react';
import { getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';
import PubSub from 'pubsub-js';
import {
  Transition,
  Grid,
  Header,
  Image,
  Form,
  Segment,
  Button,
  Message,
  Loader,
  Modal,
  Icon
} from 'semantic-ui-react';
import logoEngind from '../../imagens/logoengind.png';
import logoCidadeImperial from '../../imagens/logocidadeimperial.png';

var request = require("request");

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      Transition: false,
      mensagemSuporte: false,
      mensagemErro: '',
      usuario: '',
      senha: '',
      Loader: false,
      open: false,
      email: '',
      displayMessage: 'none',
      displayContent: '',
      displayColor: '',
      loadingbutton: false

    };
    this.exibeMensagemSuporte = this.exibeMensagemSuporte.bind(this);
    this.entrar = this.entrar.bind(this);
  }
  
  componentDidMount(){
    this.setState({ Transition: true });
  }
  
  componentWillMount() {
    PubSub.subscribe('tokenInvalido', function(topic, data){
      this.setState({ mensagemErro: "Token de segurança expirou! Faço o acesso para renovar." });
    }.bind(this));
  }
  
  open(e, item){ this.setState({ open: true })};
  close(e, item){ this.setState({ open: false })};
  
  
  //Controle da exibição da mensagem de suporte
  exibeMensagemSuporte() {
    const { mensagemSuporte } = this.state;
    this.setState({
      mensagemSuporte: mensagemSuporte === true ? false : true
    })
  }
    // Registra no banco de dados toda vez que a tela principal é carregada
  registraAcesso() {
    const request = require('request');
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = `${diaF}/${mesF}/${anoF}`;
    const horaFormatada = `${horaF}:${minutosF}`;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os;
    const opcoesAcesso = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acesso',
      headers: {
        'content-type': 'application/json'
      },
      body: {"data_acesso": dataFormatada, "hora_acesso": horaFormatada, "codigo_controle_versao": codigoVersao, 
             "key_usuario": getKeyUsuario()},
      json: true
    };
    request(opcoesAcesso, function (error, response, body) {
      
    }.bind(this));
  }
  
  //Valida os dados e realiza o acesso ao aplicativo
  entrar() {
    this.setState({ Loader: true });
    const { usuario, senha } = this.state;
    if (!usuario || !senha) {
      this.setState({ mensagemErro: 'Preencha os campos Usuário e Senha!', Loader: false });
    } else {
      const request = require('request'),
            opcoes = {
              method: 'POST',
              url:'https://inteligenciaindustrial.com/engind/login',
              headers: {
                'content-type': 'application/json'
              },
              body: {"usuario": usuario, "senha": senha, plataforma:"prodbeer"},
              json: true
            };
      request(opcoes, function (error, response, body) {
        if (body !== undefined) {
          if (body.mensagem === 'autenticacao valida') {
            localStorage.setItem("token", body.token);
            localStorage.setItem("key", body.usuario.key);
            this.registraAcesso();
            PubSub.publish('autenticacao', { validado:true });
            this.setState({ Loader:false });
          } else {
            this.setState({ mensagemErro: "Usuário ou senha inválidos! Tente novamente", Loader: false });
          }
        } else {
          this.setState({ mensagemErro: "Não foi possivel conectar-se a internet tente novamente", Loader: false });
        }
      }.bind(this));
    }
  }
  
  handleChange(event){
    this.setState({email: event.target.value, displayMessage: 'none'});
  }

  
  recuperarSenha(){
    this.setState({displayMessage: 'none', displayColor: '', displayContent: '', loadingbutton: true});
    if (this.state.email.length > 3) {
      var options = {
        method: 'GET',
        url: 'https://inteligenciaindustrial.com/engind/usuario/email/' + this.state.email,
        headers: {
          'content-type': 'application/json'
        },
        json: true
      };
      
      request(options, function(error, response, body) {
        this.setState({
          displayMessage: '', displayContent: body.mensagem, displayColor: body.color, loadingbutton: false
        });
      }.bind(this));
    } else {
      this.setState({displayMessage: '', displayContent: 'Preencho o campo E-mail', displayColor: 'red', loadingbutton: false});
    }
  }

  
  render() {
    return(
      <Transition animation = 'fade down' visible = { this.state.Transition }duration = { 3000 }>
        <div className = 'login-form'>
          <style>
            {`body > div, body > div > div, body > div > div > div.login-form { height: 100%; }`}
          </style>
          <Grid textAlign = 'center' style = {{ height: '100%' }} verticalAlign = 'middle'>
            <Grid.Column style = {{ maxWidth: '350px' }}>
              <Header as = 'h2' textAlign = 'center'>
                <Image src = { logoEngind } style = {{ width: '250px', padding: '0px', margin: 'auto'}} />
              </Header>
              <Form size = 'large'>
                <Segment stacked>
                <Form.Input fluid icon = 'user' iconPosition = 'left' placeholder = 'Usuário' 
                            type = 'text' name = 'usuario' 
                            onChange = { e => this.setState({ usuario: e.target.value, mensagemErro: '' })}/>
                <Form.Input fluid icon = 'lock' iconPosition = 'left' placeholder = 'Senha' 
                            type = 'password' name = 'senha' 
                            onChange = { e => this.setState({ senha: e.target.value,  mensagemErro: '' })}/>
                { this.state.Loader && <Loader active /> }
                <Button fluid content = 'ENTRAR' icon = 'sign-in alternate' labelPosition = 'right' 
                            color = 'orange' size = 'large' onClick = { this.entrar }/>
                </Segment>
              </Form>
              <Message> 
                Esqueceu usuário ou senha?<a href='#' onClick = {this.open.bind(this)}> Clique aqui!</a>
              </Message>
              {
                this.state.mensagemErro && 
                <Message color = 'red'> {this.state.mensagemErro} </Message>
              }
              <p class = "ui black center aligned header" style = {{ fontSize: '10px'}}> Desenvolvido por
                <Image src = { logoCidadeImperial } style = {{ width: '75px'}} />
              </p>
              <p class = "ui black center aligned header" style = {{ fontSize: '10px' }}>
                {versao}
              </p>
            </Grid.Column>

            <Modal open={this.state.open} onClose={this.close.bind(this)} size='tiny' style = {{ height: 'auto' }}>
              <Header icon='user circle outline' content='Esqueci minha senha' />
              <Modal.Content>
                <Form>
                  <Form.Field>
                    <label>E-mail</label>
                    <input placeholder='Informe seu E-mail' onChange={this.handleChange.bind(this)}/>
                  </Form.Field>
                </Form>
                <Message style = {{display: this.state.displayMessage}} color= {this.state.displayColor} closeIcon>
                  <Message.Header>{this.state.displayContent}</Message.Header>
                </Message>
              </Modal.Content>
              <Modal.Actions>
                <Button style={{backgroundColor:'#f2711c', color:'white'}} inverted onClick={this.recuperarSenha.bind(this)} loading = {this.state.loadingbutton}>
                  <Icon name='send' /> Enviar
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid>
        </div>
      </Transition>
    );
  }
}
export default Login;