import React, { Component } from 'react';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';
import PubSub from 'pubsub-js';
import {Loader, Table, Icon, Message, Image, Modal, Header, Container} from 'semantic-ui-react';

import SubCabecalho from '../genericos/subcabecalho.js';

import dwgIcon from '../../imagens/iconesarquivos/dwg.png';
import pdfIcon from '../../imagens/iconesarquivos/pdf.png';
import wordIcon from '../../imagens/iconesarquivos/word.png';
import excelIcon from '../../imagens/iconesarquivos/excel.png';
import jpgIcon from '../../imagens/iconesarquivos/jpg.png';
import tifIcon from '../../imagens/iconesarquivos/tif.png';
import msproject from '../../imagens/iconesarquivos/project.png';
import dxfIcon from '../../imagens/iconesarquivos/dxf.png';

class ListaArquivoProjeto extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      exibeMensagem: '',
      corMensagem: '',
      conteudoTabela: [],
      exibeDetalhesArquivo: false,
      detalhesArquivo: ''
    };
    this.exibeDetalhesArquivo = this.exibeDetalhesArquivo.bind(this);
  }
  
  componentDidMount() {
    let codigoArea = '';
    switch(this.props.nomeArea) {
      case 'PROJETOS CIVIS':
        codigoArea = 'ECI';
        break;
      case 'PROJETOS ELÉTRICOS':
        codigoArea = 'EEL';
        break;
      case 'PROJETOS MECÂNICOS':
        codigoArea = 'EME';
        break;
      case 'DOCUMENTAÇÃO LEGAL':
        codigoArea = 'DLE';
        break;
      case 'CRONOGRAMA FÍSICO':
        codigoArea = 'CFI';
        break;
      default:
        codigoArea = ''
    }
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    const codigoProjeto =  this.props.projeto.filter(projeto => {
      if (projeto.key === this.props.idProjeto) {
        return projeto.codigo_projeto
      }
      return null
    });
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    this.setState({ Loader: true });
    const request = require('request'),
          opcoesArquivosProjetos = {
            method: 'POST',
            url:'https://inteligenciaindustrial.com/engind/arquivos/filtrado',
            headers: {
              'content-type': 'application/json'
            },
            body: { 
              usuario: 'engind', senha: 'engind@123', codigoProjeto: codigoProjeto[0].codigo_projeto, codigoArea: codigoArea
            },
            json: true
          };
    const opcaoAcao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acaousuario',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        data_acao: dataFormatada, hora_acao: horaFormatada, nome_tela : this.props.nomeArea, codigo_projeto: codigoProjeto[0].codigo_projeto, 
        codigo_unidade_fabril: codigoProjeto[0].codigo_unidade_fabril, codigo_controle_versao: codigoVersao, key_usuario: getKeyUsuario()
      },
      json: true
    };
    request(opcaoAcao, function (error, response, body) {
      if (body.mensagem !== 'token inválido') {
        request(opcoesArquivosProjetos, function (error, response, body) {
          if (body !== undefined){
            if (body.length > 0 && this.props.idProjeto !== null){
              let ArquivosProjetos = [];
              body.map(arquivo => {
                if (arquivo.codigo_projeto === codigoProjeto[0].codigo_projeto && arquivo.area === codigoArea
                    && arquivo.path === this.props.caminhoArquivos) {
                  ArquivosProjetos.push(
                  <Table.Row key = { arquivo.key } style = {{ width: '100%' }}>
                      <Table.Cell style = {{ width: '10%', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px',
                                            paddingRight: '0px', cursor: 'pointer' }} onClick = {() => this.realizaDownload(arquivo) }>
                        <Image src={this.defineIconeArquivo(arquivo.extensao_arquivo)} size='mini' />
                      </Table.Cell>
                      <Table.Cell style = {{ width: '70%', paddingLeft: '10px', paddingRight: '0px', paddingTop: '10px', 
                                           paddingBottom: '10px', cursor: 'pointer' }} onClick = {() => this.realizaDownload(arquivo) }>
                        { arquivo.nome_arquivo }
                      </Table.Cell>
                      <Table.Cell style = {{ width: '10%', paddingLeft: '0px', paddingRight: '0px', paddingTop: '10px', 
                                           paddingBottom: '10px', cursor: 'pointer' }}>
                        {
                          (arquivo.detalhes_arquivo !== null && arquivo.detalhes_arquivo !== '') && 
                          <Icon link name = 'info circle' size = 'large' color = 'orange' onClick = {() => this.exibeDetalhesArquivo(arquivo)}/>
                        }
                      </Table.Cell>
                      <Table.Cell style = {{ width: '10%', paddingLeft: '0px', paddingRight: '0px', paddingTop: '10px',
                                           paddingBottom: '10px', cursor: 'pointer' }} onClick = {() => this.realizaDownload(arquivo) }>
                        <Icon link name='download' />
                      </Table.Cell>
                    </Table.Row>
                  );
                }
                return null
              });
              if (ArquivosProjetos.length === 0) {
                //Não foi disponibilizado cronograma físico deste projeto
                this.setState({Loader: false, exibeMensagem: `Nenhum arquivo salvo nessa pasta`, 
                             corMensagem: "orange" });
              }
              this.setState({conteudoTabela: ArquivosProjetos, Loader: false });
            } else{
              this.setState({Loader: false, exibeMensagem: 'Nenhum arquivo salvo nessa pasta', 
                             corMensagem: "orange" });
            }
          } else {
            this.setState({Loader: false });
          }
        }.bind(this));
      } else {
        this.setState({ exibeMensagem: 'Token expirou faço o acesso novamente!', corMensagem: "orange",  Loader: false });
        setTimeout(function() {
          PubSub.publish('autenticacao', { validado:false });
        }, 6000);
      }
    }.bind(this));
  }
  
  //Função que realiza o download
  realizaDownload(arquivo){
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '-' + mesF + '-' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    //hora,dia,minutos,ano,mes
    const token = Math.random() + '-' + horaF + '-' + Math.random() + '-' + diaF + '-' + Math.random() + '-' + minutosF + '-' + Math.random() + '-' + anoF + '-' + Math.random() + '-' + mesF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    if (browser.name === 'ios') {
      window.location = "googlechrome-x-callback://inteligenciaindustrial.com/engind/arquivos/downloadd/" + arquivo.key + "&" + token + "&" + dataFormatada
                + "&" + horaFormatada + "&" + codigoVersao + "&" + getKeyUsuario() + "&" + arquivo.nome_arquivo;
    } else {
      window.location = "https://inteligenciaindustrial.com/engind/arquivos/downloadd/" + arquivo.key + "&" + token + "&" + dataFormatada
                + "&" + horaFormatada + "&" + codigoVersao + "&" + getKeyUsuario() + "&" + arquivo.nome_arquivo;
    }
  }
  //Função que define o icone do arquivo
  defineIconeArquivo(extensao) {
    switch(extensao) {
      case 'pdf':
        return pdfIcon;
      case 'dwg':
        return dwgIcon;
      case 'jpg':
        return jpgIcon;
      case 'png':
        return jpgIcon;
      case 'docx':
        return wordIcon;
      case 'tif':
        return tifIcon;
      case 'xlsx':
        return excelIcon;
      case 'mpp':
        return msproject;
        case 'dxf':
        return dxfIcon;
      default:
        return '';
    } 
  }

//
exibeDetalhesArquivo(arquivo) {
  if (this.state.exibeDetalhesArquivo) {
    this.setState({
      exibeDetalhesArquivo: false
    });
  } else {
    let conteudoModal = '';
    if (arquivo.detalhes_arquivo !== null && arquivo.detalhes_arquivo !== '') {
      conteudoModal = arquivo.detalhes_arquivo;
    }
    this.setState({
      detalhesArquivo: conteudoModal, exibeDetalhesArquivo: true
    });
  }
}
  
  render() {
    const { conteudoTabela } = this.state;
    return(
      <div>
          <SubCabecalho tituloTela = { this.props.descricao_geral + ' - ' + this.props.nomeArea } 
                        opcaoTela = {'renderizaPastas'}/>
          { this.state.conteudoTabela.length > 0 &&
            <Table unstackable style = {{ padding:'0px', width:'100%' }}>
              <Table.Body style={{ fontSize:'12px', display: 'block', margin: 'auto', 
                                                        height: '480px', width:'100%', paddingLeft: '0px', 
                                                        paddingRight: '0px', overflow: 'auto'}}>
                { conteudoTabela }
              </Table.Body>
            </Table>
          }
          { this.state.Loader && 
            <Loader active inline='centered'/> 
          }
          {
            this.state.exibeMensagem && 
            <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
          }
          <Modal closeIcon size = 'mini' open = { this.state.exibeDetalhesArquivo } onClose = { this.exibeDetalhesArquivo }>
          <Header color = 'orange' icon='info circle' content='Detalhes' />
            <Modal.Content>
              <Container>
                <p>{this.state.detalhesArquivo}</p>
              </Container>
            </Modal.Content>
        </Modal>
      </div>
    );
  }
}
//
export default ListaArquivoProjeto;
