import React, { Component } from 'react';
import SubCabecalho from '../genericos/subcabecalho.js';
import { Segment, Card, Icon, Image, Button, Loader, Message } from 'semantic-ui-react';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';
import PubSub from 'pubsub-js';

class registroExecucaoGeral extends Component {
  constructor(props){
    super(props);
    this.state = {
      ArrayCodImagens: [],
      ArrayEtapasImagens: [],
      TotalEtapasCarregadas: 0,
      TotalEtapasCarregar: 10,
      EtapasExibidas: [],
      Loader: false,
      exibeMensagem: '',
      corMensagem: '',
      botaoMaisFoto: true
    }
    this.renderizaFotos = this.renderizaFotos.bind(this);
    this.exibeMaisFotos = this.exibeMaisFotos.bind(this);
    
  }
  
  componentDidMount(){
    this.setState({Loader: true});
    const request = require('request');
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    
    const postAcao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acaousuario',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        data_acao: dataFormatada, hora_acao: horaFormatada, nome_tela : "REGISTRO DE EXECUÇÃO GERAL", codigo_projeto: "", 
        codigo_unidade_fabril: "", codigo_controle_versao: codigoVersao, key_usuario: getKeyUsuario()
      },
      json: true
    };
    const postImagens = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/arquivos/registroexecucaogeral',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        usuario: 'engind', senha: 'engind@123'
      },
      json: true
    };
    const postEtapasImagem = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/etapaimagem/registroexecucaogeral',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        codigoUnidadeFabril: this.props.codigoUnidadeFabril
      },
      json: true
    };
    
    request(postAcao, function (error, response, body) {
      if (body.mensagem !== 'token inválido') {
        request(postImagens, function (error, response, body) {
          let ArrayCodImagens = [], ArrayEtapasImagens = [], ImagensCarregar = [];
          if (body.ImagensRegistroExecucaoGeral !== undefined) {
            ArrayCodImagens = body.ImagensRegistroExecucaoGeral
            request(postEtapasImagem, function (error, response, body) {
              if (body.EtapasImagens !== undefined) {
                //FILTRA OS PROJETOS DA FILIAL SELECIONADA
                let ArrayProjetosFiltrado = this.props.projeto.filter(p => {
                  return p.codigo_unidade_fabril === this.props.codigoUnidadeFabril
                });
                let ArrayCodProjetosFiltrado = [];
                ArrayProjetosFiltrado.forEach(p => {
                  ArrayCodProjetosFiltrado.push(p.codigo_projeto);
                });
                let ArrayCodImagensFiltrado = [];
                //FILTRA
                 ArrayCodImagens.forEach(i => {
                   if (ArrayCodProjetosFiltrado.indexOf(i.codigo_projeto) !== -1) {
                       ArrayCodImagensFiltrado.push(i)
                  }
                });
                ArrayEtapasImagens = body.EtapasImagens
                let totalCarregar = ArrayCodImagensFiltrado.length >= 10 ? 10 : ArrayCodImagensFiltrado.length;
                for (var index = 0; index < totalCarregar; index++) {
                  ImagensCarregar.push(ArrayCodImagensFiltrado[index])
                }
                this.setState({
                  ArrayCodImagens: ArrayCodImagensFiltrado, ArrayEtapasImagens: ArrayEtapasImagens
                });
                this.renderizaFotos(ImagensCarregar, ArrayEtapasImagens);
              } else {

              }
            }.bind(this));
          } else {

          }
        }.bind(this));
      } else {
        this.setState({ exibeMensagem: 'Token expirou faço o acesso novamente!', corMensagem: "orange",  Loader: false });
        setTimeout(function() {
          PubSub.publish('autenticacao', { validado:false });
        }, 6000);
      }
    }.bind(this));
  }
  
  exibeMaisFotos(){
    let { ArrayCodImagens, TotalEtapasCarregadas, TotalEtapasCarregar, ArrayEtapasImagens } = this.state;
    let ImagensCarregar = [];
    let numImg = 0;
    if (TotalEtapasCarregar+11 < ArrayCodImagens.length) {
      numImg = 11;
    } else {
      numImg = parseInt(ArrayCodImagens.length - TotalEtapasCarregar);
      this.setState({
        
      })
    }
    for (var index = TotalEtapasCarregadas+11; index < TotalEtapasCarregar+numImg; index++) {
      ImagensCarregar.push(ArrayCodImagens[index])
    }
    this.renderizaFotos(ImagensCarregar, ArrayEtapasImagens);
    
  }
  
  
  renderizaFotos(ImagensCarregar, ArrayEtapasImagens) {
    this.setState({Loader: true});
    let { EtapasExibidas } = this.state;
    const request = require('request');
    for (var index = 0; index < ImagensCarregar.length; index++) {
      ArrayEtapasImagens.filter(registro => {
        if (registro.codigo_imagem === ImagensCarregar[index].key) {
          ImagensCarregar[index].codigo_etapa = registro.codigo_etapa
        }
      });
    }
    const postConverteImagens = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/arquivos/imagensregistroexecucaogeral',
      headers: {
        'content-type': 'application/json'
      },
      body: { 
        usuario: 'engind', senha: 'engind@123', imagens: ImagensCarregar
      },
      json: true
    };
    request(postConverteImagens, function(error, response, body) {
      if (body !== undefined) {
        if (body.ImagensConvertidas.length) {
          let ImagensBase64 = body.ImagensConvertidas;
          for (var index = 0; index < ImagensBase64.length; index++) {
            if (ImagensBase64[index] !== null) {
              const EtapaImagem = ArrayEtapasImagens.filter(registro => {
                return registro.codigo_etapa === ImagensBase64[index].keyEtapaImagen && 
                       registro.codigo_projeto === ImagensBase64[index].codigoProjeto &&
                       registro.codigo_imagem === ImagensBase64[index].codigoImagem
              });
              let key = Math.random();
              if (EtapaImagem[0] !== undefined) {
                ImagensBase64[index].key = key;
                ImagensBase64[index].nomeProjeto = EtapaImagem[0].descricao_projeto;
                ImagensBase64[index].descricaoEtapa = EtapaImagem[0].descricao_etapa;
              } else {
                ImagensBase64[index].key = key;
                ImagensBase64[index].nomeProjeto = "";
                ImagensBase64[index].descricaoEtapa = "";
              }
            } else {
              console.log("Imagem não econtrada no diretorio");
            }
          }
          let ComponenteEtapas = EtapasExibidas;
          if (EtapasExibidas.length !== 0) {
            for (var i = 0; i < ImagensBase64.length; i++) {
              if (ImagensBase64[index] !== null) {
                ComponenteEtapas.push(ImagensBase64[i])
              }
            }
            this.setState({EtapasExibidas: ComponenteEtapas, TotalEtapasCarregadas: this.state.TotalEtapasCarregadas+10,
                          TotalEtapasCarregar: this.state.TotalEtapasCarregar+10, Loader: false, exibeMensagem: ""})
          } else {
            this.setState({EtapasExibidas: ImagensBase64, Loader: false, exibeMensagem: ""})
          }
        } else {
          //Não retornou imagens
          const { EtapasExibidas } = this.state;
          this.setState({EtapasExibidas: EtapasExibidas, Loader: false, botaoMaisFoto: false, exibeMensagem: ""})
        }
      } else {
        //Erro com internet
        this.setState({ exibeMensagem: "Não foi possivel conectar-se a internet tente novamente", corMensagem: "red",
                        Loader: false });
      }
      
    }.bind(this));
  }
  
  downloadImg(item){
    console.log("download");
    console.log(item)
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '-' + mesF + '-' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    //hora,dia,minutos,ano,mes
    const token = Math.random() + '-' + horaF + '-' + Math.random() + '-' + diaF + '-' + Math.random() + '-' + minutosF + '-' + Math.random() + '-' + anoF + '-' + Math.random() + '-' + mesF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    if (browser.name === 'ios') {
      window.location = "googlechrome-x-callback://inteligenciaindustrial.com/engind/arquivos/downloadd/" + item.codigoImagem + "&" + token + "&" + dataFormatada
                + "&" + horaFormatada + "&" + codigoVersao + "&" + getKeyUsuario() + "&" + item.nomeArquivo;
    } else {
      window.location = "https://inteligenciaindustrial.com/engind/arquivos/downloadd/" + item.codigoImagem + "&" + token + "&" + dataFormatada
                + "&" + horaFormatada + "&" + codigoVersao + "&" + getKeyUsuario() + "&" + item.nomeArquivo;
    }
  }
  
  render(){
    return(
      <div>
        <SubCabecalho tituloTela = {"REGISTROS DE EXECUÇÃO GERAL"}
                      opcaoTela = {"registroExecucaoGeral"} />
        <Segment style = {{fontSize:'12px', paddingTop: '20px', paddingLeft: '0px', paddingRight: '0px' ,
                           height: '480px', width:'100%', overflowY: 'auto',  overflowX: 'hidden'}}>
          { this.state.EtapasExibidas.length === 0 && this.state.Loader && <Loader active ></Loader> }
          {
            this.state.exibeMensagem && 
            <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
          }
          {
            this.state.EtapasExibidas.map(function(item) {
              if (item !== null) {
                let v = "data:image/png;base64,"+item.base64;
                return <Card key = {item.key} style = {{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Image src = {v} wrapped ui={false} />
                        <Card.Content>
                          <Card.Header>{item.descricaoEtapa}</Card.Header>
                          <Card.Description>
                            <span className='date'>{item.dataImagem}</span><br/>
                            {item.nomeProjeto}
                          </Card.Description>
                          <div style = {{textAlign: 'right'}}>
                            <Icon link name='download' size='large' onClick = {this.downloadImg.bind(this, item)}/>
                          </div>
                        </Card.Content>
                      </Card>
              }
            }.bind(this))
          }
          {
            this.state.EtapasExibidas.length !== 0 && this.state.Loader === false && this.state.botaoMaisFoto === true &&
            <div style = {{width: '90%', margin: 'auto'}}>
              <Button color = "orange" fluid onClick = {this.exibeMaisFotos}>
                <Icon name='images outline' size='large' />
                Exibir mais fotos
              </Button>
              
            </div>
          }
          {
            this.state.EtapasExibidas.length !== 0 && this.state.Loader === true && this.state.botaoMaisFoto === true && 
            <div style = {{width: '90%', margin: 'auto'}}>
              <Button loading color = "orange" fluid disabled onClick = {this.exibeMaisFotos}>
                <Icon name='images outline' size='large'/>
                Exibir mais fotos
              </Button>
            </div>
          }
          {
            this.state.exibeMensagem &&
            <div style = {{width: '90%', margin: 'auto', paddingTop: '10px'}}>
              <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
            </div>
          }
          {
            this.state.EtapasExibidas.length === 0 && this.state.Loader === false && this.state.botaoMaisFoto === false && 
            <div style = {{width: '90%', margin: 'auto'}}>
              <Button loading color = "orange" fluid disabled onClick = {this.exibeMaisFotos}>
                <Icon name='images outline' size='large'/>
                Exibir mais fotos
              </Button>
            </div>
          }
        </Segment>
      </div>
    );
  }
}

export default registroExecucaoGeral