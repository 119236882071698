import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import SubCabecalho from '../genericos/subcabecalho.js';
import BotaoGrande from '../genericos/botaogrande.js';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import {
  Grid,
  Divider,
  Button,
  Icon
} from 'semantic-ui-react';

const { detect } = require('detect-browser');
const browser = detect();


class MenuCentral extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }
  
  exibeRegistroExecucaoGeral() {
    PubSub.publish('opcaoComponente', { opcaoComponente:'registroExecucaoGeral' });
  }
  
  cronogramaFisicoGeral() {
    PubSub.publish('opcaoComponente', { opcaoComponente:'cronogramafisicogeral' });
  }
  
  fluxoCaixaGeral() {
    PubSub.publish('opcaoComponente', { opcaoComponente:'fluxoCaixaGeral' });
  }
  
  exibeBuscaArquivos() {
    PubSub.publish('opcaoComponente', { opcaoComponente:'buscaArquivoAgrupado' });
  }
  
  render(){
    let Botoes = [], tituloTela = '';
    let opcTela =  this.props.parametroDescricao;
    if (this.props.opcoesMenu.length > 0) {
      if (this.props.parametroDescricao === 'fabrica') {
        tituloTela = this.props.tituloTela;
        this.props.opcoesMenu.map(opcao => {
          Botoes.push(
            <BotaoGrande nomeBotao = { opcao.descricao_unidade_fabril } key = { opcao.id } 
                                       id = { opcao.id } parametroDescricao = { 'fabrica' } 
                                       unicoBotao = {this.props.opcoesMenu.length > 1 ? true : false}
                                       usuario = {this.props.usuario}
                                       unidadesFabril = {this.props.unidadesFabril}/>
          );
          return '';
        });
      }
      if (this.props.parametroDescricao === 'statusProjeto') {
        opcTela = 'statusProjeto';
        let descricaoUnidade = '';
        this.props.unidadesFabril.filter(unidade => {
          if (unidade.id === this.props.idFabrica) {
            descricaoUnidade = unidade.descricao_unidade_fabril
          }
          return 0;
        });
        tituloTela = 'SELECIONE O STATUS DE PROJETO  ' + descricaoUnidade;
        this.props.opcoesMenu.map(opcao => {
          Botoes.push(
            <BotaoGrande nomeBotao = { opcao.descricao_status_projeto } key = { opcao.id } 
                                       id = { opcao.id } parametroDescricao = { 'statusProjeto' }/>
          );
          return '';
        });
        Botoes.push(
          <BotaoGrande nomeBotao = { 'TODOS' } key = { null } 
                                       id = { null } parametroDescricao = { 'statusProjeto' }/>
        );
      }
      if (this.props.parametroDescricao === 'carregarProjeto') {
        opcTela = 'carregarProjeto';
        tituloTela = this.props.descricao_geral;
        Botoes = [
          <BotaoGrande nomeBotao = { 'ESCOPO' } key = { 1 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'REGISTRO DE EXECUÇÃO' } key = { 2 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'CRONOGRAMA FÍSICO' } key = { 3 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'DOCUMENTAÇÃO LEGAL' } key = { 4 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'PROJETOS CIVIS' } key = { 5 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'PROJETOS ELÉTRICOS' } key = { 6 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'PROJETOS MECÂNICOS' } key = { 7 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'CRONOGRAMA FINANCEIRO' } key = { 8 } 
                       id = { this.props.idProjeto } parametroDescricao = { 'carregarProjeto' }
                       usuario = {this.props.usuario}/>
        ];
      }
      
      if (this.props.parametroDescricao === 'modoExibicaoArquivos') {
        tituloTela = "SELECIONE O MODO DE EXIBIÇÃO DOS ARQUIVOS"
        Botoes = [
          <BotaoGrande nomeBotao = { 'LISTA DE ARQUIVOS' } key = { 0 } 
                       parametroDescricao = { 'buscaArquivo' }
                       usuario = {this.props.usuario}/>,
          <BotaoGrande nomeBotao = { 'ARQUIVOS AGRUPADOS' } key = { 1 } 
                       parametroDescricao = { 'buscaArquivo' }
                       usuario = {this.props.usuario}/>
        ];
      }
    }
    return(
      <div>
        <SubCabecalho tituloTela = {tituloTela}
                      opcaoTela = {opcTela} />
        <Grid columns='equal' style = {{margin: '0px', padding: '0px'}}>
          {Botoes}
        </Grid>
        {this.props.parametroDescricao === 'statusProjeto' && 
          <Grid>
            <Grid.Row style = {{ paddingBottom: '0px', paddingTop: '0px' }}>
              <Grid.Column width={16} textAlign='center' style={{margin: '0px', padding: '0px'}}>
                <Button color = "orange" style = {{ margin: 'auto', width: '89%' }}
                        onClick = {this.exibeRegistroExecucaoGeral}>
                  <Icon name='images outline' size='large' />
                  REGISTROS DE EXECUÇÃO GERAL
                </Button>
              </Grid.Column>
            </Grid.Row>
            { (browser.os !== 'iOS' && browser.os !== 'Android OS') &&
            <Grid.Row style = {{ paddingBottom: '0px' }}>
              <Grid.Column width={16} textAlign='center' style={{margin: '0px', padding: '0px'}}>
                <Button color = "orange" style = {{margin: 'auto', width: '89%'}}
                        onClick = {this.cronogramaFisicoGeral}>
                  <Icon name='chart line' size='large' />
                  CRONOGRAMA FÍSICO GERAL
                </Button>
              </Grid.Column>
            </Grid.Row>
            }
            {/*
            <Grid.Row style = {{ paddingBottom: '0px' }}>
              <Grid.Column width={16} textAlign='center' style={{margin: '0px', padding: '0px'}}>
                <Button color = "orange" style = {{margin: 'auto', width: '89%'}}
                        onClick = {this.fluxoCaixaGeral}>
                  <Icon name='money' size='large' />
                  FLUXO DE CAIXA GERAL
                </Button>
              </Grid.Column>
            </Grid.Row>      
            */}
            <Grid.Row>
              <Grid.Column width={16} textAlign='center' style={{margin: '0px', padding: '0px'}}>
                <Button color = "orange" style = {{margin: 'auto', width: '89%'}}
                        onClick = {this.exibeBuscaArquivos}>
                  <Icon name='file' size='large' />
                  DOCUMENTOS
                </Button>
              </Grid.Column>
            </Grid.Row>
        </Grid>
        }
      </div>
    );
  }
}

export default MenuCentral;