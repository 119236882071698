import React, { Component } from 'react';
import {
  Grid,
  Table,
  Loader,
  Message,
  Label,
  Form,
  Select,
  Segment
} from 'semantic-ui-react';
import SubCabecalho from '../genericos/subcabecalho.js';
import PubSub from 'pubsub-js';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';

class CronogramaFinanceiro extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      exibeMensagem: '',
      corMensagem: '',
      ArrayCronograma: [],
      ArrayCronogramaPlanejado: [],
      ArrayCronogramaReal: [],
      totalPlanejado: 0,
      totalReal: 0,
      Fornecedores: [],
      opcaoFornecedor: 'TODOS'
    };
    this.alteraFornecedor = this.alteraFornecedor.bind(this);
  }
  
  componentDidMount() {
    this.setState({ Loader: true });
    let CronogramaFinanceiroFiltrado = null, CronogramaPlanejado = [], CronogramaReal = [],
        totalPlanejado = 0, totalReal = 0, ArrayFornecedores = [];
    //filtra os projetos 
    const ProjetoFiltrado =  this.props.projeto.filter(projeto => {
      return projeto.key === this.props.idProjeto
    });
    const formatDecimal = require('format-decimal');
          const opts =  {
            decimal   : ',',
            precision : 2,
            thousands : '.'
          };
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    //definição dos parametros para a requisição na api de controle de arquivos
    const request = require('request');
    const opcao = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/engind/cronogramafinanceiro',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        usuario: 'engind', senha: 'engind@123' 
      },
      json: true
    };
    const opcaoAcao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acaousuario',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        data_acao: dataFormatada, hora_acao: horaFormatada, nome_tela : this.props.nomeTela, codigo_projeto: ProjetoFiltrado[0].codigo_projeto, 
        codigo_unidade_fabril: ProjetoFiltrado[0].codigo_unidade_fabril, codigo_controle_versao: versao, key_usuario: getKeyUsuario()
      },
      json: true
    };
    request(opcaoAcao, function (error, response, body){
      if(body.mensagem !== 'token inválido'){
        //faz o request na api
        request(opcao, function (error, response, body){
          if (body !== undefined && body.length > 0) {
            CronogramaFinanceiroFiltrado = body.filter(cronograma => {
              return cronograma.codigo_projeto === ProjetoFiltrado[0].codigo_projeto
            });
            if (CronogramaFinanceiroFiltrado.length > 0) {
              ArrayFornecedores.push("TODOS");
              CronogramaFinanceiroFiltrado.map(cronograma => {
                ArrayFornecedores.push(cronograma.descricao_fornecedor);
                if (cronograma.planejado_ou_real === 'planejado') {
                  totalPlanejado += parseFloat(cronograma.valor);
                  CronogramaPlanejado.push(
                    <Table.Row key = {cronograma.id}>
                      <Table.Cell style = {{fontSize: '11px'}} >
                        {cronograma.data}<br />
                        <Label color='grey' size = 'mini'>{cronograma.descricao_fornecedor}</Label>
                      </Table.Cell>
                      <Table.Cell style = {{fontSize: '10px'}}>{formatDecimal(parseFloat(cronograma.valor), opts)}</Table.Cell>
                    </Table.Row>
                  );
                }
                if (cronograma.planejado_ou_real === 'real') {
                  totalReal += parseFloat(cronograma.valor);
                  CronogramaReal.push(
                    <Table.Row key = {cronograma.id}>
                      <Table.Cell style = {{fontSize: '11px'}} >
                        {cronograma.data}<br />
                        <Label color='grey' size = 'mini'>{cronograma.descricao_fornecedor}</Label>
                      </Table.Cell>
                      <Table.Cell style = {{fontSize: '10px'}}>{formatDecimal(parseFloat(cronograma.valor), opts)}</Table.Cell>
                    </Table.Row>
                  );
                } 
              return ''
              });
              //remove os registros duplicados
              let ArrayFornecedoresFiltrados = ArrayFornecedores.filter(function(este, i) {
                return ArrayFornecedores.indexOf(este) === i;
              });
              let OpcoesFornecedores = ArrayFornecedoresFiltrados.map(fornecedor => {
                return { key: fornecedor, text: fornecedor, value: fornecedor }
              });
              this.setState({
                Loader: false, ArrayCronograma: CronogramaFinanceiroFiltrado, ArrayCronogramaPlanejado: CronogramaPlanejado, ArrayCronogramaReal: CronogramaReal,
                totalPlanejado: formatDecimal(totalPlanejado, opts), totalReal: formatDecimal(totalReal, opts), Fornecedores: OpcoesFornecedores
              });
            } else {
              this.setState({ exibeMensagem: 'Nenhum registro de cronograma financeiro encontrado', 
                              corMensagem: "orange", Loader: false});
            }
          } else {
            this.setState({ exibeMensagem: 'Nenhum registro de cronograma financeiro encontrado', 
                            corMensagem: "orange", Loader: false});
          }
          if (body.mensagem) {
            this.setState({ exibeMensagem: 'Token expirou faço o acesso novamente!', corMensagem: "orange",  Loader: false });
            setTimeout(function() {
              PubSub.publish('autenticacao', { validado:false });
            }, 6000);
          }
        }.bind(this))
      } else {
        this.setState({ exibeMensagem: 'Token expirou faço o acesso novamente!', corMensagem: "orange",  Loader: false });
        setTimeout(function() {
          PubSub.publish('autenticacao', { validado:false });
        }, 6000);
      }
    }.bind(this))
  }
  //
  alteraFornecedor(e, {value,name}){
    this.setState({ Loader: true });
    let CronogramaFinanceiroFiltrado = [], CronogramaPlanejado = [], CronogramaReal = [], totalPlanejado = 0, totalReal = 0;
    const { ArrayCronograma } = this.state;
    const formatDecimal = require('format-decimal');
    const opts =  {
      decimal   : ',',
      precision : 2,
      thousands : '.'
    };
    console.log(value);
    if (value !== 'TODOS') {
      CronogramaFinanceiroFiltrado = ArrayCronograma.filter(cronograma => {
      return cronograma.descricao_fornecedor === value
    });
    } else {
      CronogramaFinanceiroFiltrado = ArrayCronograma;
    }
    CronogramaFinanceiroFiltrado.map(cronograma => {
      if (cronograma.planejado_ou_real === 'planejado') {
        totalPlanejado += parseFloat(cronograma.valor);
        CronogramaPlanejado.push(
          <Table.Row key = {cronograma.id}>
            <Table.Cell style = {{fontSize: '11px'}} >
              {cronograma.data}<br />
              <Label color='grey' size = 'mini'>{cronograma.descricao_fornecedor}</Label>
            </Table.Cell>
            <Table.Cell style = {{fontSize: '10px'}}>{formatDecimal(parseFloat(cronograma.valor), opts)}</Table.Cell>
          </Table.Row>
        );
      }
      if (cronograma.planejado_ou_real === 'real') {
        totalReal += parseFloat(cronograma.valor);
        CronogramaReal.push(
          <Table.Row key = {cronograma.id}>
            <Table.Cell style = {{fontSize: '11px'}} >
              {cronograma.data}<br />
              <Label color='grey' size = 'mini'>{cronograma.descricao_fornecedor}</Label>
            </Table.Cell>
            <Table.Cell style = {{fontSize: '10px'}}>{formatDecimal(parseFloat(cronograma.valor), opts)}</Table.Cell>
          </Table.Row>
        );
      }
      return ''
    });
    this.setState({
      Loader: false, ArrayCronogramaPlanejado: CronogramaPlanejado, ArrayCronogramaReal: CronogramaReal,
      totalPlanejado: formatDecimal(totalPlanejado, opts), totalReal: formatDecimal(totalReal, opts), opcaoFornecedor: value
    });
  }
  
  render() {
    return(
      <div>
        <SubCabecalho tituloTela = { "CRONOGRAMA FINANCEIRO - " + this.props.descricao_geral } 
                      opcaoTela = {'escopotela'} />
        { this.state.Loader && <Loader active inline='centered'/> }
        { this.state.ArrayCronograma.length > 0 &&
          <div>
            <Form>
              <Form.Field control = { Select } name = 'fornecedor' onChange = {this.alteraFornecedor} 
                          value = {this.state.opcaoFornecedor} style = {{marginBottom: '25px'}} options = {this.state.Fornecedores}/>
            </Form>
            <Segment style = {{display: 'block', margin: 'auto', height: '480px', overflowY: 'scroll'}}>
              <Grid columns='equal'>
                <Grid.Column style = {{ paddingLeft: "5px", paddingTop: "10px", width: "45%"}}>
                  <Table unstackable celled>
                    <Table.Header>
                      <Table.Row textAlign = 'center'>
                        <Table.HeaderCell colSpan = '2' style = {{fontSize: '12px'}}>PLANEJADO</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body >
                      {this.state.ArrayCronogramaPlanejado}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell style = {{fontSize: '11px'}}>TOTAL</Table.HeaderCell>
                        <Table.HeaderCell style = {{fontSize: '10px'}}>{this.state.totalPlanejado}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Column>
                <Grid.Column style = {{ paddingRight: "5px", paddingTop: "10px", width: "45%" }}>
                  <Table unstackable celled>
                    <Table.Header>
                      <Table.Row textAlign = 'center'>
                        <Table.HeaderCell colSpan = '2' style = {{fontSize: '12px'}}>REAL</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.ArrayCronogramaReal}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell style = {{fontSize: '11px'}}>TOTAL</Table.HeaderCell>
                        <Table.HeaderCell style = {{fontSize: '10px'}}>{this.state.totalReal}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Column>
              </Grid>
            </Segment>
          </div>
        }
        {
          this.state.exibeMensagem && 
          <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
        }
      </div>
    );
  }
}
//
export default CronogramaFinanceiro;