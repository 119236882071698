import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import {
  Button,
  Grid,
  Message,
  Modal,
  Header
} from 'semantic-ui-react';

class BotaoGrande extends Component {
  constructor(props){
    super(props);
    this.state = {
      exibeMensagem: '',
      corMensagem: '',
      parametroDescricao: '',
      exibeModal: false
    };
    this.enviaOpcao = this.enviaOpcao.bind(this);
  }
  
  
  componentDidMount() {
    this.setState({parametroDescricao: this.props.parametroDescricao});
    //console.log(this.props);
  }
  
  enviaOpcao(id, parametroDescricao) {
    if (parametroDescricao === 'fabrica') {
      const codigoUnidadeFabril = this.props.unidadesFabril.filter(unidade => {
        return unidade.descricao_unidade_fabril === this.props.nomeBotao
      });
      //console.log(codigoUnidadeFabril[0].codigo_unidade_fabril);
      let unidadeUsuario = null;
      if (this.props.usuario[0].perfil === 'ASSISTENTE') {
        unidadeUsuario = this.props.unidadesFabril.filter(unidade => {
          return unidade.descricao_unidade_fabril === this.props.usuario[0].filial
        });
        if (unidadeUsuario[0].id === id) {
          PubSub.publish('idFabrica', { idFabrica:id });
          PubSub.publish('opcaoComponente', { opcaoComponente:'selecionarStatus' });
        } else {
          this.setState({exibeMensagem: 'Você não possui acesso a essa unidade.', 
                       corMensagem: "orange", exibeModal: true });
        }
      } else {
        PubSub.publish('idFabrica', { idFabrica:id });
        PubSub.publish('opcaoComponente', { opcaoComponente:'selecionarStatus' });
      }
      PubSub.publish('codigoUnidadeFabril', { codigoUnidadeFabril:codigoUnidadeFabril[0].codigo_unidade_fabril });
      PubSub.publish('abreviaturaUnidadeFabril', { codigoUnidadeFabril:codigoUnidadeFabril[0].abreviatura_unidade_fabril });
    }
    if (parametroDescricao === 'statusProjeto') {
      PubSub.publish('idStatus', { idStatus:id });
      PubSub.publish('opcaoComponente', { opcaoComponente:'listaProjeto' });
    }
    if (parametroDescricao === 'carregarProjeto' && this.props.nomeBotao === 'ESCOPO') {
      PubSub.publish('idProjeto', { idProjeto:id });
      PubSub.publish('opcaoComponente', { opcaoComponente:'escopoProjeto' });
    }
    
    if (parametroDescricao === 'carregarProjeto' && 
        (this.props.nomeBotao === 'PROJETOS CIVIS' || this.props.nomeBotao === 'DOCUMENTAÇÃO LEGAL' ||
        this.props.nomeBotao === 'PROJETOS ELÉTRICOS' || this.props.nomeBotao === 'PROJETOS MECÂNICOS' ||
        this.props.nomeBotao === 'CRONOGRAMA FÍSICO')) {
      if (this.props.usuario[0].perfil !== 'ASSISTENTE') {
        if (this.props.nomeBotao === 'DOCUMENTAÇÃO LEGAL' && this.props.usuario[0].perfil !== 'ADMINISTRADOR' &&
                                                         this.props.usuario[0].perfil !== 'ENGENHEIRO') {
          this.setState({exibeMensagem: 'Você não possui acesso a essa opção.', 
                         corMensagem: "orange", exibeModal: true });
        } else {
          PubSub.publish('idProjeto', { idProjeto:id });
          PubSub.publish('area', { area:this.props.nomeBotao });
          PubSub.publish('opcaoComponente', { opcaoComponente:'renderizaPasta' });
        }
      } else {
        if (this.props.nomeBotao === 'CRONOGRAMA FÍSICO') {
          PubSub.publish('idProjeto', { idProjeto:id });
          PubSub.publish('area', { area:this.props.nomeBotao });
          PubSub.publish('opcaoComponente', { opcaoComponente:'renderizaPasta' });
        } else {
            this.setState({exibeMensagem: 'Você não possui acesso a essa opção.', 
                         corMensagem: "orange", exibeModal: true });
        }
      }
    }
    if (parametroDescricao === 'carregarProjeto' && this.props.nomeBotao === 'REGISTRO DE EXECUÇÃO') {
      PubSub.publish('opcaoComponente', { opcaoComponente:'registroExecucao' });
    }
    if (parametroDescricao === 'carregarProjeto' && this.props.nomeBotao === 'CRONOGRAMA FINANCEIRO') {
      if (this.props.usuario[0].perfil !== 'ADMINISTRADOR') {
        this.setState({exibeMensagem: 'Você não possui acesso a essa opção.', 
                       corMensagem: "orange", exibeModal: true });
      } else {
        PubSub.publish('opcaoComponente', { opcaoComponente:'cronogramaFinanceiro' });
      }
    }
    
    if (parametroDescricao === 'buscaArquivo') {
      if (this.props.nomeBotao === 'LISTA DE ARQUIVOS') {
        PubSub.publish('opcaoComponente', { opcaoComponente:'buscaArquivoLista' });
      }
      
      if (this.props.nomeBotao === 'ARQUIVOS AGRUPADOS') {
        PubSub.publish('opcaoComponente', { opcaoComponente:'buscaArquivoAgrupado' });
      }
    }
  }
  
  fecharModalObservacoes = () => this.setState({ exibeModal: false });
    
  render(){
    let width = this.props.unicoBotao === false ? '160px' : '90%';
    return(
        <Grid.Column textAlign='center' width = { this.props.unicoBotao === false ? '16' : '8' } style={{margin: '0px', padding: '0px'}}>
          <Button style = {{ width: width, height: "80px", marginTop: '0px', marginBottom: '10px', marginLeft: '0px',
                             marginRight: '0px', padding: '0px'}}
                             onClick = { this.enviaOpcao.bind(this, this.props.id, this.props.parametroDescricao) }>{ this.props.nomeBotao }</Button>
          {
          this.state.exibeMensagem && 
          <Modal size = 'mini' open = { this.state.exibeModal } onClose = { this.fecharModalObservacoes } closeIcon>
            <Header icon='info circle' color = 'orange' content='Aviso' />
            <Modal.Content>
              <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
            </Modal.Content>
          </Modal>
        }
        
        </Grid.Column>
    );
  }
}

export default BotaoGrande;