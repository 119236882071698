import React, { Component } from 'react';
import './../../index.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';
import PubSub from 'pubsub-js';
import { Loader, Card, Grid, Message, Segment, Icon, Modal, Container, Label, Header,Dimmer, Image } from 'semantic-ui-react';
import SubCabecalho from '../genericos/subcabecalho.js';

class RegistroExecucao extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      LoaderModal: false,
      exibeMensagem: '',
      corMensagem: '',
      ConteudoCard: [],
      exibeDetalhesCronograma: false,
      detalhesEtapa: '',
      exibeFotos: false,
      EtapaImagem: null,
      Arquivos: null,
      ConteudoCarousel: [],
      ImagensConvertidas: [],
      ImagensFiltrada: []
    };
    this.exibeDetalhesCronograma = this.exibeDetalhesCronograma.bind(this);
    this.exibeFotos = this.exibeFotos.bind(this);
  }
  //
  componentDidMount() {
    this.setState({ Loader: true });
    let EtapasFiltradas = null, ImagensFiltrada = null, ImagensConvertidas = null, ConteudoCard = null
    //filtra os projetos 
    const ProjetoFiltrado =  this.props.projeto.filter(projeto => {
      return projeto.key === this.props.idProjeto
    });
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    //codigo do projeto
    const codProjeto = ProjetoFiltrado[0].codigo_projeto;
    const request = require('request');
    const opcoesEtapas = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/etapa/filtrado',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { "codigo_projeto": codProjeto },
      json: true
    };
    const opcoesEtapaImagem = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/etapaimagem/filtrado',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { "codigo_projeto": codProjeto },
      json: true
    };
    const opcaoAcao = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acaousuario',
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      body: { 
        data_acao: dataFormatada, hora_acao: horaFormatada, nome_tela : this.props.nomeTela, codigo_projeto: codProjeto, 
        codigo_unidade_fabril: ProjetoFiltrado[0].codigo_unidade_fabril, codigo_controle_versao: codigoVersao, key_usuario: getKeyUsuario()
      },
      json: true
    };
    request(opcaoAcao, function (error, response, body) {
      if (body.mensagem !== 'token inválido') {
        request(opcoesEtapas, function (error, response, body) {
          EtapasFiltradas = body;
          request(opcoesEtapaImagem, function (error, response, body) {
            ImagensFiltrada = body;
            if (EtapasFiltradas.length > 0) {
              ConteudoCard = EtapasFiltradas.map(etapa => {
                return(
                  <Card centered key = { etapa.id } style = {{ width: '100%', paddingBottom: '10px' }}>
                    <Card.Content onClick = { this.verificaSeExisteImagem(ImagensFiltrada, etapa) ?
                                              () => this.exibeFotos(ImagensFiltrada, etapa) :
                                              null}>
                      <Card.Header>
                        <Grid>
                          <Grid.Column floated='left' width={11}>
                            <strong style = {{ fontSize: '16px', color: '#eb5c0a' }}>{etapa.descricao_etapa}</strong>
                          </Grid.Column>
                          <Grid.Column floated='right' width={2}>
                            { (etapa.detalhes_etapa !== null && etapa.detalhes_etapa !== '') && 
                              <Icon color = 'orange' link name = 'info circle' size = 'large' style = {{paddingBottom: '30px'}}
                                    onClick = {() => this.exibeDetalhesCronograma(etapa) } />
                            }
                          </Grid.Column>
                          <Grid.Column floated = 'right' width = {3}>
                            { (this.verificaSeExisteImagem(ImagensFiltrada, etapa) !== false) && 
                               <Icon color = 'orange' link name = 'images' size = 'large'
                                     onClick = {() => this.exibeFotos(ImagensFiltrada, etapa) }/>
                            }
                          </Grid.Column>
                        </Grid>
                      </Card.Header>
                      <Grid columns='equal'>
                        <Grid.Column width={8} style = {{ paddingTop: '0px'}}>
                          <strong>INÍCIO</strong><br /> {etapa.inicio_real}
                        </Grid.Column>
                        <Grid.Column width={8} style = {{ paddingTop: '0px'}}>
                          <strong>FIM</strong><br /> {etapa.fim_real}
                        </Grid.Column>
                      </Grid>
                      <Label attached = 'bottom right' size = 'tiny' color='orange' style = {{fontSize: '8px'}}>
                        {etapa.status}
                      </Label>
                    </Card.Content>
                  </Card>
                );
              });
              this.setState({
                ConteudoCard: ConteudoCard, Loader: false
              });
            } else {
              this.setState({
                exibeMensagem: 'Nenhum registro de execução encontrado', corMensagem: "orange", ConteudoCard: [], 
                Loader: false
              });
            }
          }.bind(this));
        }.bind(this));
      } else {
        this.setState({ exibeMensagem: 'Token expirou faço o acesso novamente!', corMensagem: "orange",  Loader: false });
        setTimeout(function() {
          PubSub.publish('autenticacao', { validado:false });
        }, 6000);
      }
    }.bind(this));
  }
  
  verificaSeExisteImagem(ImagensFiltrada, etapa) {
    let totalEncontrado = 0;
    for (let index = 0; index < ImagensFiltrada.length; index++) {
      if(ImagensFiltrada[index].codigo_etapa === etapa.codigo_etapa) {
        totalEncontrado++;
      }
    }
    if (totalEncontrado > 0) {
      return true
    } else {
      return false
    }
  }
  
  exibeDetalhesCronograma(etapa) {
    if (this.state.exibeDetalhesCronograma) {
      this.setState({
        exibeDetalhesCronograma: false
      });
    } else {
      let conteudoModal = '';
      if (etapa.detalhes_etapa !== null && etapa.detalhes_etapa !== '') {
        conteudoModal = etapa.detalhes_etapa;
      }
      this.setState({
        detalhesEtapa: conteudoModal, exibeDetalhesCronograma: true
      });
      this.setState({
        exibeDetalhesCronograma: true
      });
    }
  }

exibeFotos(ImagensFiltrada, etapa) {
  this.setState({ ImagensFiltrada: [], LoaderModal: true });
  let Imgfiltrada = ImagensFiltrada;
  if (this.state.exibeFotos) {
    this.setState({
      exibeFotos: false
    });
  } else {
    this.setState({ exibeFotos: true });
    const img = Imgfiltrada.filter(a => {
      return a.codigo_etapa === etapa.codigo_etapa
    });
    const request = require('request');
    const opcoesConvertImagens = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/arquivos/imagens',
      headers: {
        'content-type': 'application/json'
      },
      body: { 
        usuario: 'engind', senha: 'engind@123', imagens: img, codigo_projeto: etapa.codigo_projeto
      },
      json: true
    };
    request(opcoesConvertImagens, function (error, response, body) {
      this.setState({
        ImagensFiltrada: body.ImagensConvertidas, LoaderModal: false
      });
      //console.log(body);
    }.bind(this));
  }
}
//
render() {
    return(
      <div>
        <SubCabecalho tituloTela = { this.props.descricao_geral + " - REGISTRO DE EXECUÇÃO" } opcaoTela = {'escopotela'} />
        { this.state.Loader && <Loader active inline='centered'/> }
        { this.state.ConteudoCard.length > 0 &&
          <Segment.Group style = {{paddingTop: '0px'}}>
            <Segment.Group horizontal>
              <Card.Group textAlign = 'center' style={{ fontSize:'12px', display: 'block', margin: 'auto', 
                                                  height: '480px', width:'100%', paddingLeft: '10px', 
                                                  paddingRight: '10px', overflow: 'auto'}}>
                { this.state.ConteudoCard }
              </Card.Group>
            </Segment.Group>
          </Segment.Group>
        }
        <Modal closeIcon size = 'mini' open = { this.state.exibeDetalhesCronograma } onClose = { this.exibeDetalhesCronograma }>
          <Header color = 'orange' icon='info circle' content='Detalhes' />
            <Modal.Content>
              <Container>
                <p>
                  {this.state.detalhesEtapa}
                </p>
              </Container>
            </Modal.Content>
        </Modal>
        <Modal style = {{minHeight: '60%'}} closeIcon size = 'tiny' open = { this.state.exibeFotos } onClose = { this.exibeFotos }>
          <Header color = 'orange' icon='images' content='Imagens' />
            <Modal.Content>
              { this.state.LoaderModal && 
                <Segment disabled style = {{height:"300px"}}>
                  <Dimmer active inverted>
                    <Loader size='large'></Loader>
                  </Dimmer>
                </Segment>
              }

              
              <Carousel >
                  {this.state.ImagensFiltrada.map(function(item) {
                    let v = "data:image/png;base64,"+item.base64;
                    return <div key = {item.keyEtapaImagen}>
                              <img src = {v} />
                              <p className="legend" style = {{ backgroundColor: '#f2711c', width: "100px" }}>{item.dataImagem}</p>
                              
                           </div>
                   })
                  }
                </Carousel>

            </Modal.Content>
        </Modal>
        {
          this.state.exibeMensagem && 
          <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
        }
      </div>
    );
  }
}
//
export default RegistroExecucao;