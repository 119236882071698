import React, { Component } from 'react';
import {
  Table
} from 'semantic-ui-react';

class CardEscopoProjeto extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  
  render(){
    return(
      <Table unstackable style = {{ width:'100%' }} >
        <Table.Header >
          <Table.Row>
            <Table.HeaderCell>{ this.props.tituloCard }</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body style={{ fontSize:'14px', display: 'block', paddingLeft: '10px', paddingBottom: '10px', paddingTop: '10px' }}>
          <Table.Row>
            { this.props.conteudoCard }
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default CardEscopoProjeto;