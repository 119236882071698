import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import { tokenValido, removerToken, getKeyUsuario } from '../servicos/validatoken.js';
import Login from './login/login.js';
import Corpo from './corpo.js';
import { versao } from '../servicos/informacoes.js';

import BuscaArquivos from './buscaarquivos/buscaarquivos.js';

class Main extends Component {
  constructor(props){
    super(props);
    this.state = { 
      component:'login'
    };
  }
  
  
  // Registra no banco de dados toda vez que a tela principal é carregada
  registraAcesso() {
    const request = require('request');
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = `${diaF}/${mesF}/${anoF}`;
    const horaFormatada = `${horaF}:${minutosF}`;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    const opcoesAcesso = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/engind/acesso',
      headers: {
        'content-type': 'application/json'
      },
      body: {"data_acesso": dataFormatada, "hora_acesso": horaFormatada, "codigo_controle_versao": codigoVersao, 
             "key_usuario": getKeyUsuario()},
      json: true
    };
    request(opcoesAcesso, function (error, response, body) {
      
    }.bind(this));
  }

  
  componentDidMount(){
    //Verifca se existe token salvo no localStorage
    if (tokenValido()) {
      this.registraAcesso();
      this.setState({ component: 'principal' });
    } else{
      this.setState({ component: 'login' })
    }
    
    PubSub.subscribe('autenticacao', function (topic, data) {      
      if(data.validado === true){
        this.setState({ component: 'principal' })
      }else if(data.validado === false){
        removerToken();
        this.setState({ component: 'login'})
      }
    }.bind(this));        
  }
  
  render() {
    /*return(
      <div style = {{maxWidth: '85%', margin: 'auto'}}>
        <BuscaArquivos />
      </div>}  
    );*/
    
    if (this.state.component === 'login') {
      return (<Login/>)
    } else if (this.state.component === 'principal') {
      return (<Corpo/>);
    }
      
  }
}

export default Main;