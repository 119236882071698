import React, { Component } from 'react';
import {
  Loader
} from 'semantic-ui-react';
import SubCabecalho from '../genericos/subcabecalho.js';
import CardEscopoProjeto from '../genericos/cardescopoprojeto.js';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';

class EscopoProjeto extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      ConteudoCard: null,
    };
  }
  
  componentDidMount() {
    this.setState({ Loader: true });
    const data = new Date(),
          dia  = data.getDate().toString(),
          diaF = (dia.length === 1) ? '0'+dia : dia,
          mes  = (data.getMonth()+1).toString(),
          mesF = (mes.length === 1) ? '0'+mes : mes,
          anoF = data.getFullYear(),
          hora = data.getHours().toString(),
          horaF = (hora.length === 1) ? '0'+hora : hora,
          minutos = data.getMinutes().toString(),
          minutosF = (minutos.length === 1) ? '0'+minutos : minutos;
    const dataFormatada = diaF + '/' + mesF + '/' + anoF;
    const horaFormatada = horaF + ':' + minutosF;
    const { detect } = require('detect-browser');
    const browser = detect();
    const codigoVersao = versao +" - "+ browser.name +" - "+ browser.version +" - "+ browser.os; 
    const request = require('request');
    let Projeto = null;
    if (this.props.projeto !== undefined && this.props.idProjeto !== undefined) {
      Projeto = this.props.projeto.filter(projeto => {
        return projeto.key === this.props.idProjeto
      });
      const opcaoAcao = {
        method: 'POST',
        url:'https://inteligenciaindustrial.com/engind/acaousuario',
        headers: {
          'content-type': 'application/json',
          'x-access-token': getToken()
        },
        body: { 
          data_acao: dataFormatada, hora_acao: horaFormatada, nome_tela : this.props.nomeTela, codigo_projeto: Projeto[0].codigo_projeto, 
          codigo_unidade_fabril: Projeto[0].codigo_unidade_fabril, codigo_controle_versao: codigoVersao, key_usuario: getKeyUsuario()
        },
        json: true
      };
      request(opcaoAcao, function (error, response, body) {
        if (body.mensagem !== 'token inválido') {
          const conteudoCard = Projeto.map(projeto => {
            return (
              <div >
                <CardEscopoProjeto idProjeto = { projeto.id }
                                   tituloCard = { 'NOME DO PROJETO' }
                                   conteudoCard = { projeto.descricao_projeto } />
                <CardEscopoProjeto idProjeto = { projeto.id }
                                   tituloCard = { 'DESCRIÇÃO DO PROJETO' }
                                   conteudoCard = { projeto.descricao_geral } />
                <CardEscopoProjeto idProjeto = { projeto.id }
                                   tituloCard = { 'OBJETIVO DO PROJETO' }
                                   conteudoCard = { projeto.objetivo } />
                <CardEscopoProjeto idProjeto = { projeto.id }
                                   tituloCard = { 'ESCOPO DO PROJETO' }
                                   conteudoCard = { projeto.escopo } />
                <CardEscopoProjeto idProjeto = { projeto.id }
                                   tituloCard = { 'DATA' }
                                   conteudoCard = { projeto.data } />
              </div>
            );
          });
          this.setState({Loader: false, ConteudoCard: conteudoCard});
        }
      }.bind(this));
    }
  }
  
  render() {
    const { ConteudoCard } = this.state;
    return(
      <div>
        <SubCabecalho tituloTela = { this.props.descricao_geral + " - ESCOPO" } opcaoTela = {'escopotela'} />
        { this.state.Loader && <Loader active inline='centered'/> }
        {ConteudoCard}
      </div>
    );
  }
}
//
export default EscopoProjeto;