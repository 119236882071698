import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import {
  Grid,
  Header,
  Icon
} from 'semantic-ui-react';

class SubCabecalho extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.voltar = this.voltar.bind(this);
  }
  
  voltar(){
    if (this.props.opcaoTela === 'statusProjeto' || this.props.opcaoTela === 'modoExibicaoArquivos' ||
        this.props.opcaoTela === 'alterarSenha' ) {
      PubSub.publish('opcaoComponente', { opcaoComponente:'' });
    }
    if (this.props.opcaoTela === 'listaProjeto' || this.props.opcaoTela === 'registroExecucaoGeral' ||
        this.props.opcaoTela === 'cronogramafisicoGeral' || this.props.opcaoTela === 'buscaArquivos' ||
        this.props.opcaoTela === 'fluxoCaixaGeral') {
      PubSub.publish('opcaoComponente', { opcaoComponente:'selecionarStatus' });
    }
    if (this.props.opcaoTela === 'carregarProjeto') {
      PubSub.publish('opcaoComponente', { opcaoComponente:'listaProjeto' });
    }
    if (this.props.opcaoTela === 'escopotela') {
      PubSub.publish('opcaoComponente', { opcaoComponente:'carregarProjeto' });
    }
    if (this.props.opcaoTela === 'listaArquivosProjeto') {
      PubSub.publish('posicaoCaminho', { posicaoCaminho: 0 });
      PubSub.publish('opcaoComponente', { opcaoComponente:'carregarProjeto' });
    }
    if (this.props.opcaoTela === 'renderizaPastas') {
      PubSub.publish('opcaoComponente', { opcaoComponente:'renderizaPasta' });
    }
    if (this.props.opcaoTela === 'modoExibicaoArquivo') {
      PubSub.publish('opcaoComponente', { opcaoComponente:'modoExibicaoArquivo' });
    }
  }
  
  render(){
    return(
      <div>
        <Grid style={{ height: '100%'}}>
          <Grid.Row textAlign='center' >
            <Grid.Column width = {2} ></Grid.Column>
            <Grid.Column width = {12} style = {{paddingTop: '0px'}}>
              <Header as = 'h5'>{this.props.tituloTela}</Header>
            </Grid.Column>
            <Grid.Column width = {2}>
              {
                this.props.opcaoTela !== 'fabrica' && 
                <Icon floated='right' name='reply' link style = {{paddingTop: '0px'}} 
                      onClick = {this.voltar}/>
              }
              <br/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style = {{paddingTop: '10px'}}>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default SubCabecalho;