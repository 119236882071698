import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import {
  Header,
  Grid,
  Icon,
  Image,
  Divider,
  Sidebar,
  Menu,
  Segment,
  Message,
  Loader
} from 'semantic-ui-react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet

import logoEngind from '../imagens/logoengind.png';
import AtualizaDadosCadastrais from './atualizadadoscadastrais/atualizadadoscadastrais.js';
import MenuCentral from './menucentral/menucentral.js';
import ListaProjeto from './listaprojeto/listaprojeto.js';
import EscopoProjeto from './informacoesprojeto/escopoprojeto.js';
import RenderizaPasta from './renderizapasta/renderizapasta.js';
import ListaArquivoProjeto from './informacoesprojeto/listaarquivosprojeto.js';
import RegistroExecucao from './informacoesprojeto/registroExecucao.js';
import CronogramaFinanceiro from './informacoesprojeto/cronogramafinanceiro.js';
import BuscaArquivos from './buscaarquivos/buscaarquivos.js';
import RegistroExecucaoGeral from './registroexecucaogeral/registroexecucaogeral.js';
import CronogramafisicoGeral from './cronogramafisicogeral/cronogramafisicogeral.js';
import FluxoCaixaGeral from './fluxocaixageral/fluxocaixageral.js';
import { getToken, getKeyUsuario } from '../servicos/validatoken.js';

class Corpo extends Component{
  constructor(props) {
    super(props);
    this.state = {
      Loader: false,
      exibeMenuSuperior: false,
      exibeMensagem: '',
      corMensagem: '',
      renderizaComponente: '',
      UnidadesFabril: '',
      idFabrica: '',
      idStatus: '',
      idProjeto: '',
      projeto: '',
      descricao_geral: '',
      Area:'',
      Usuario: '',
      PerfilUsuario: '',
      nomeArea: '',
      caminhoArquivos: '',
      posicaoCaminho: '',
      nomePastaAnterior: '',
      codigoUnidadeFabril: '',
      abreviaturaUnidadeFabril: '',
      mensagenLoader: '',
      caminhoPastas: '',
      Projetos: []
    };
    this.exibeMenuSuperior = this.exibeMenuSuperior.bind(this);
    this.exibeComponenteUnidadesFabril = this.exibeComponenteUnidadesFabril.bind(this);
    this.exibeComponenteAlterarSenha = this.exibeComponenteAlterarSenha.bind(this);
    this.exibeComponenteBuscarArquivos = this.exibeComponenteBuscarArquivos.bind(this);
    this.sair = this.sair.bind(this);
  }
  
  componentDidMount(){
    window.localStorage.setItem('indexPasta', 0);
    window.localStorage.setItem('CaminhoPastas', JSON.stringify([]));
    this.setState({ Loader: true });
    const request = require('request'),
          opcoesUnidadesFabril = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/unidadefabril',
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          },opcoesProjetos = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/projeto',
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          },
          opcoesStatusProjeto = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/statusprojeto',
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          },opcoesArea = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/area',
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          },
          opcoesUsuario = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/usuario/' + getKeyUsuario(),
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          },
          opcoesPerfilUsuario = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/perfilusuario',
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          };
    request(opcoesUnidadesFabril, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          this.setState({UnidadesFabril: body, Loader: false})
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        //quando não consegue consultar a API
        console.log("Erro");
      }
    }.bind(this));
    //
    request(opcoesProjetos, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          this.setState({Projetos: body})
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        //quando não consegue consultar a API
        console.log("Erro");
      }
    }.bind(this));
    //
    request(opcoesStatusProjeto, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          this.setState({StatusProjeto: body})
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        //quando não consegue consultar a API
        console.log("Erro");
      }
    }.bind(this));
    //
    request(opcoesArea, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          this.setState({Area: body})
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        //quando não consegue consultar a API
        console.log("Erro");
      }
    }.bind(this));
    //
    request(opcoesUsuario, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          this.setState({Usuario: body})
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        //quando não consegue consultar a API
        console.log("Erro");
      }
    }.bind(this));
    //
    request(opcoesPerfilUsuario, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          this.setState({PerfilUsuario: body})
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        //quando não consegue consultar a API
        console.log("Erro");
      }
    }.bind(this));
  }
  
  componentWillMount(){
    PubSub.subscribe('opcaoComponente', function(topic, data){
      this.setState({renderizaComponente: data.opcaoComponente})
    }.bind(this));
    PubSub.subscribe('idFabrica', function(topic, data){
      this.setState({idFabrica: data.idFabrica})
    }.bind(this));
    PubSub.subscribe('idStatus', function(topic, data){
      this.setState({idStatus: data.idStatus})
    }.bind(this));
    PubSub.subscribe('idProjeto', function(topic, data){
      this.setState({idProjeto: data.idProjeto})
    }.bind(this));
    PubSub.subscribe('projeto', function(topic, data){
      this.setState({projeto: data.projeto})
    }.bind(this));
    PubSub.subscribe('descricao_geral', function(topic, data){
      this.setState({descricao_geral: data.descricao_geral})
    }.bind(this));
    PubSub.subscribe('area', function(topic, data){
      this.setState({nomeArea: data.area})
    }.bind(this));
    PubSub.subscribe('caminhoArquivos', function(topic, data){
      this.setState({caminhoArquivos: data.caminhoArquivos})
    }.bind(this));
    PubSub.subscribe('posicaoCaminho', function(topic, data){
      this.setState({posicaoCaminho: data.posicaoCaminho})
    }.bind(this));
    PubSub.subscribe('nomePastaAnterior', function(topic, data){
      this.setState({nomePastaAnterior: data.nomePastaAnterior});
    }.bind(this));
    PubSub.subscribe('codigoUnidadeFabril', function(topic, data){
      this.setState({codigoUnidadeFabril: data.codigoUnidadeFabril})
    }.bind(this));
    PubSub.subscribe('abreviaturaUnidadeFabril', function(topic, data){
      this.setState({abreviaturaUnidadeFabril: data.codigoUnidadeFabril});
    }.bind(this));
    PubSub.subscribe('caminhoPastas', function(topic, data){
      this.setState({caminhoPastas: data.caminhoPastas})
    }.bind(this));
  }
  
  sair() {
    window.localStorage.setItem('indexPasta', 0);
    window.localStorage.setItem('CaminhoPastas', JSON.stringify([]));
    PubSub.publish('autenticacao', { validado:false });
  }
  
  exibeMenuSuperior() {
    const { exibeMenuSuperior } = this.state;
    this.setState({
      exibeMenuSuperior: exibeMenuSuperior === true ? false : true
    });

  }
  
  exibeComponenteUnidadesFabril() {
    window.localStorage.setItem('indexPasta', 0);
    window.localStorage.setItem('CaminhoPastas', JSON.stringify([]));
    this.setState({
      posicaoCaminho: 0, renderizaComponente: '', caminhoPastas: ''
    });
  }
  
  exibeComponenteAlterarSenha() {
    this.setState({
      renderizaComponente: 'alterarSenha'
    });
  }
  
  exibeComponenteBuscarArquivos() {
    this.setState({
      renderizaComponente: 'modoExibicaoArquivo'
    });
  }
  
  render(){
    const { exibeMenuSuperior, renderizaComponente } = this.state;
    let objetoComponente;
    //Controle de exibição de compoentes
    switch(renderizaComponente) {
      case 'alterarSenha':
        objetoComponente = <AtualizaDadosCadastrais />
        break;
      case 'selecionarStatus':
        objetoComponente = <MenuCentral opcoesMenu = {this.state.StatusProjeto}
                                        parametroDescricao = {'statusProjeto'}
                                        unidadesFabril = {this.state.UnidadesFabril}
                                        idFabrica = {this.state.idFabrica}
                                        usuario = {this.state.Usuario}/>
        break;
      case 'listaProjeto':
        objetoComponente = <ListaProjeto idStatus = { this.state.idStatus }
                                         statusProjeto = { this.state.StatusProjeto }
                                         unidadesFabril = {this.state.UnidadesFabril} 
                                         idFabrica = {this.state.idFabrica}
                                         projeto = { this.state.Projetos }
                                         codigoUnidadeFabril = {this.state.codigoUnidadeFabril} />
        break;
      case 'carregarProjeto':
        objetoComponente = <MenuCentral opcoesMenu = {'carregarProjeto'}
                                        parametroDescricao = {'carregarProjeto'}
                                        idProjeto = {this.state.idProjeto}
                                        descricao_geral = {this.state.descricao_geral}
                                        usuario = {this.state.Usuario} />
        
        break;
      case 'escopoProjeto':
        objetoComponente = <EscopoProjeto idProjeto = {this.state.idProjeto}
                                          descricao_geral = {this.state.descricao_geral}
                                          projeto = { this.state.projeto }
                                          nomeTela = 'ESCOPO'/>
        
        break;
      case 'renderizaPasta':
        objetoComponente = <RenderizaPasta idProjeto = {this.state.idProjeto}
                                            descricao_geral = {this.state.descricao_geral}
                                            projeto = { this.state.projeto }
                                            Area = {this.state.Area}
                                            nomeArea = { this.state.nomeArea }
                                            posicaoCaminho = { this.state.posicaoCaminho  }
                                            nomePastaAnterior = { this.state.nomePastaAnterior }
                                            caminhoPastas = { this.state.caminhoPastas }/>
        
        break;
      case 'listaArquivosProjeto':
        objetoComponente = <ListaArquivoProjeto idProjeto = {this.state.idProjeto}
                                                descricao_geral = {this.state.descricao_geral}
                                                projeto = { this.state.projeto }
                                                Area = {this.state.Area}
                                                nomeArea = { this.state.nomeArea }
                                                caminhoArquivos = { this.state.caminhoArquivos }
                                                posicaoCaminho = { this.state.posicaoCaminho  }/>
        
        break;
      case 'registroExecucao':
        objetoComponente = <RegistroExecucao    descricao_geral = {this.state.descricao_geral}
                                                idProjeto = {this.state.idProjeto}
                                                projeto = { this.state.projeto } 
                                                nomeTela = 'REGISTRO DE EXECUÇÃO'/>
        
        break;
      case 'cronogramaFinanceiro':
        objetoComponente = <CronogramaFinanceiro descricao_geral = {this.state.descricao_geral} 
                                                 idProjeto = {this.state.idProjeto}
                                                 projeto = { this.state.projeto } 
                                                 nomeTela = 'CRONOGRAMA FINANCEIRO'/>
        
        break;
      case 'modoExibicaoArquivo':
        objetoComponente = <MenuCentral  parametroDescricao = {'modoExibicaoArquivos'}
                                         opcoesMenu = {'modoExibicaoArquivos'}
                                         usuario = {this.state.Usuario}
                                         unidadesFabril = {this.state.UnidadesFabril}/>
        break;
      case 'buscaArquivoAgrupado':
        objetoComponente = <BuscaArquivos parametroDescricao = {'modoExibicaoArquivos'}
                                          usuario = {this.state.Usuario}
                                          modoExibicao = {'buscaArquivoAgrupado'}
                                          idFabrica = {this.state.idFabrica}
                                          unidadesFabril = {this.state.UnidadesFabril}/>
        break;
      case 'buscaArquivoLista':
        objetoComponente = <BuscaArquivos parametroDescricao = {'modoExibicaoArquivos'}
                                          usuario = {this.state.Usuario}
                                          modoExibicao = {'buscaArquivoLista'}
                                          idFabrica = {this.state.idFabrica}
                                          unidadesFabril = {this.state.UnidadesFabril}/>
        break;
      case 'registroExecucaoGeral':
        objetoComponente = <RegistroExecucaoGeral usuario = {this.state.Usuario}
                                                  abreviaturaUnidadeFabril = {this.state.abreviaturaUnidadeFabril}
                                                  codigoUnidadeFabril = {this.state.codigoUnidadeFabril}
                                                  projeto = { this.state.Projetos }/>
        break;
      case 'cronogramafisicogeral':
        objetoComponente = <CronogramafisicoGeral usuario = {this.state.Usuario}
                                                  abreviaturaUnidadeFabril = {this.state.abreviaturaUnidadeFabril}
                                                  codigoUnidadeFabril = {this.state.codigoUnidadeFabril}
                                                  projeto = { this.state.Projetos } 
                                                  modoExibicao = {'cronogramafisicogeral'}/>
        break;
      case 'fluxoCaixaGeral':
        objetoComponente = <FluxoCaixaGeral usuario = {this.state.Usuario}
                                            abreviaturaUnidadeFabril = {this.state.abreviaturaUnidadeFabril}
                                            codigoUnidadeFabril = {this.state.codigoUnidadeFabril}
                                             modoExibicao = {'fluxoCaixaGeral'}/>
        break;
      default:
        objetoComponente = <MenuCentral tituloTela = {'SELECIONE UMA FÁBRICA'}
                                        opcoesMenu = {this.state.UnidadesFabril}
                                        parametroDescricao = {'fabrica'}
                                        projeto = { this.state.Projetos }
                                        usuario = {this.state.Usuario}
                                        unidadesFabril = {this.state.UnidadesFabril}/>
    }
    
    return(
      <div style = {{ maxWidth: '100%', margin: 'auto'}} >
        <Header style={{width: '100%', height: '70px'}}>
          <Grid>
            <Grid.Column width={2}>
              <Icon name = 'sidebar' link style={{ marginTop:'20px', marginLeft:'10px' }} disabled = {exibeMenuSuperior} 
                    onClick = { this.exibeMenuSuperior }/>
            </Grid.Column >
            <Grid.Column width={12} style={{textAlign: 'center'}}>
              <Header.Content>
                <Image src = { logoEngind } style = {{ width: '130px', paddingTop: '8px', cursor: 'pointer'}} 
                       onClick = { this.exibeComponenteUnidadesFabril }/>
              </Header.Content>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid>
        </Header>
        <Divider style = {{paddingTop: '0px', marginBottom: '0px'}} />
        <Sidebar.Pushable style = {{ padding: '0px'}}>
          <Sidebar as = { Menu } animation = 'overlay' inverted onHide = { this.exibeMenuSuperior } 
                   direction = 'top' visible = { exibeMenuSuperior } width = 'thin'>
            <Menu.Item as = 'a' onClick = { this.exibeComponenteAlterarSenha }>
              <Icon name = 'edit outline' />
                Alterar Senha
            </Menu.Item>
            <Menu.Item as = 'a' onClick = { this.sair }>
              <Icon name = 'log out' />
                Sair
            </Menu.Item>
          </Sidebar>
          <Sidebar.Pusher>
            <Segment basic style = {{maxWidth: objetoComponente.props.modoExibicao !== undefined ? "100%" : "450px", margin: 'auto'}}>
              { 
                !this.state.exibeMensagem &&
                objetoComponente 
              }
              { this.state.Loader && <Loader active inline='centered'>{this.state.mensagenLoader}</Loader> }
              {
                this.state.exibeMensagem && 
                <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
              }
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}
export default Corpo;