import React, { Component } from 'react';
import SubCabecalho from '../genericos/subcabecalho.js';
import { Card, Icon, Loader, Accordion, Grid, Message } from 'semantic-ui-react';
import { getToken, getKeyUsuario } from '../../servicos/validatoken.js';
import { versao } from '../../servicos/informacoes.js';
import PubSub from 'pubsub-js';

const request = require('request');
const formatDecimal = require('format-decimal');
const opcFormata =  {
  decimal   : ',',
  precision : 0,
  thousands : '.'
};

class fluxoCaixaGeral extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeIndex: -1,
      activeIndexContrato: -1,
      Fornecedores: [], 
      Contratos: [], 
      Anos: [], 
      Dados: [],
      Data: [],
      Total: [],
      TotalGeral: [],
      TotalGeralFiltrado: [],
      TotalGeralFornecedor: [],
      TotalGeralFornecedorFiltrado: [],
      TotalGeralContrato: [],
      TotalGeralContratoFiltrado: [],
      loader: true,
      mensagenAviso: '',
      exibeMensagenAvisoModal: false
    }
    
    this.atualizaDados = this.atualizaDados.bind(this);
  }
  
  componentDidMount(){
    console.log(this.props.codigoUnidadeFabril)
    
    let Fornecedores = [], Contratos = [], Anos = [], Dados = [], 
        TotalGeral = [], TotalGeralFiltrado = [],
        TotalGeralFornecedor = [], TotalGeralFornecedorFiltrado = [],
        TotalGeralContrato = [], TotalGeralContratoFiltrado = [];
    let anoAtual = new Date().getFullYear();
    
    const getTotalGeral = {
      method: 'GET',
      url:`http://52.201.66.71:4000/engind/fluxocaixa/totalgeral/${this.props.codigoUnidadeFabril}`,
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    }
    
    const getTotalgeralFornecedor = {
      method: 'GET',
      url:`http://52.201.66.71:4000/engind/fluxocaixa/totalgeralFornecedor/${this.props.codigoUnidadeFabril}`,
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    }
    
    const getTotalgeralContrato = {
      method: 'GET',
      url:`http://52.201.66.71:4000/engind/fluxocaixa/totalgeralContrato/${this.props.codigoUnidadeFabril}`,
      headers: {
        'content-type': 'application/json',
        'x-access-token': getToken()
      },
      json: true
    }
    
    request(getTotalGeral, function(error, response, body){
      if (body.length > 1) {
        TotalGeral = body;
      
        TotalGeralFiltrado = TotalGeral.filter(function(total){
          return total.identificador_mes === ' ' || parseInt(total.titulo) === anoAtual
        });

        request(getTotalgeralFornecedor, function(error, response, body){
          TotalGeralFornecedor = body;

          TotalGeralFornecedor.forEach(function(fornecedor, index){
            if (Fornecedores.indexOf(fornecedor.agrupador) === -1) {
              Fornecedores.push(fornecedor.agrupador)
            }
          });

          TotalGeralFornecedorFiltrado = TotalGeralFornecedor.filter(function(total){
            return total.titulo === "Geral" || parseInt(total.ano) === anoAtual || total.identificador_mes === ""
          });

          request(getTotalgeralContrato, function(error, response, body){
            TotalGeralContrato = body;

            TotalGeralContrato.forEach(function(contrato, index){
              let result = Contratos.findIndex(val => val.subagrupador === contrato.subagrupador);

              if (result < 0) {
                Contratos.push({agrupador: contrato.agrupador, subagrupador: contrato.subagrupador});
              }
            });

            TotalGeralContratoFiltrado = TotalGeralContrato.filter(function(total){
              return total.titulo === "Geral" || parseInt(total.ano) === anoAtual || total.identificador_mes === ""
            });

            this.setState( {
              Fornecedores: Fornecedores, Contratos: Contratos, Anos: Anos, Dados: Dados,
              TotalGeral: TotalGeral, TotalGeralFiltrado: TotalGeralFiltrado, TotalGeralFornecedor: TotalGeralFornecedor,
              TotalGeralFornecedorFiltrado: TotalGeralFornecedorFiltrado, TotalGeralContrato: TotalGeralContrato, 
              TotalGeralContratoFiltrado: TotalGeralContratoFiltrado, loader: false
            } );
          }.bind(this))
        }.bind(this));
      } else {
        this.setState({ loader: false, exibeMensagenAvisoModal: true, mensagenAviso: "Não foi salvo nenhum registro de fluxo de caixa para está unidade."});
      }
    }.bind(this));
  }
  
  
  
  
  
  
  
  
  
  
  
  
  atualizaDados(dados){
    let {  TotalGeral, TotalGeralFornecedor, TotalGeralContrato } = this.state;
    let ano = dados.titulo
    
    
    
    const TotalGeralFiltrado = TotalGeral.filter(function(total){
      return total.identificador_mes === ' ' || parseInt(total.titulo) === parseInt(ano) 
    });
    
    const TotalGeralFornecedorFiltrado = TotalGeralFornecedor.filter(function(total){
      return total.titulo === "Geral" || parseInt(total.ano) === parseInt(ano)  || total.identificador_mes === ""
    });
    
    const TotalGeralContratoFiltrado = TotalGeralContrato.filter(function(total){
      return total.titulo === "Geral" || parseInt(total.ano) === parseInt(ano)  || total.identificador_mes === ""
    });
    
    this.setState({
      TotalGeralFiltrado: TotalGeralFiltrado, TotalGeralFornecedorFiltrado: TotalGeralFornecedorFiltrado,
      TotalGeralContratoFiltrado: TotalGeralContratoFiltrado
    });
  }
  
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }
  
  handleClickContrato = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndexContrato } = this.state
    const newIndex = activeIndexContrato === index ? -1 : index

    this.setState({ activeIndexContrato: newIndex })
  }
  
  
  render(){
    const { activeIndex, activeIndexContrato, TotalGeralFiltrado, TotalGeralFornecedorFiltrado, Fornecedores, Contratos, TotalGeralContratoFiltrado, exibeMensagenAvisoModal } = this.state
    
    return(
      <div style = {{minHeight: '120px'}}>
        <SubCabecalho tituloTela = {"FLUXO DE CAIXA GERAL"}
                      opcaoTela = {"fluxoCaixaGeral"}/>
        {this.state.loader && <Loader active style = {{marginTop: '40px'}}/>}
      
        { this.state.exibeMensagenAvisoModal &&
          <Message warning style = {{fontSize: '12px', textAlign: 'center'}}>
            <Message.Header>{this.state.mensagenAviso}</Message.Header>
          </Message>
        }
        
        {!exibeMensagenAvisoModal && 
          <Accordion style = {{ paddingBottom: '5px', paddingTop: '0px', display: this.state.loader ? 'none': ''}}>
            <Accordion.Title active = {activeIndex === '01'}
                             index = {'01'}
                             onClick = {this.handleClick}>
              <Icon name = 'dropdown' />
              TOTAL
            </Accordion.Title>
            <Accordion.Content active = {activeIndex === '01'}>
              <Grid container columns={4}>
                <Grid.Row>
                {
                  TotalGeralFiltrado.map(function(total, index){
                    if (total.identificador_mes === ' ') {
                      return(
                        <Grid.Column style = {{textAlign: 'right'}} >
                          <Card style={{boxShadow: 'none'}} onClick =  { total.titulo !== 'Geral' ? () => this.atualizaDados(total) : '' }>
                            <Card.Meta style={{fontWeight: 'bold'}}>{total.titulo}</Card.Meta>
                            <Card.Meta style={{color: 'black', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_mes), opcFormata) }</Card.Meta>
                            <Card.Meta style={{color: 'green', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_pago_mes), opcFormata) }</Card.Meta>
                            <Card.Meta style={{color: 'red', fontSize: '10px'}}>{ formatDecimal(parseInt(total.saldo_pagar_mes), opcFormata) }</Card.Meta>
                          </Card>
                        </Grid.Column>
                      )
                    }
                  }.bind(this))
                }
                </Grid.Row>
              </Grid>

              <Grid container columns={4}>
                <Grid.Row>
                {
                  TotalGeralFiltrado.map(function(total, index){
                    if (total.identificador_mes !== ' ') {
                      return(
                        <Grid.Column style = {{textAlign: 'right'}} >
                          <Card style={{boxShadow: 'none'}} >
                            <Card.Meta style={{fontWeight: 'bold'}}>{total.subtitulo +" "+ total.titulo}</Card.Meta>
                            <Card.Meta style={{color: 'black', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_mes), opcFormata) }</Card.Meta>
                            <Card.Meta style={{color: 'green', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_pago_mes), opcFormata) }</Card.Meta>
                            <Card.Meta style={{color: 'red', fontSize: '10px'}}>{ formatDecimal(parseInt(total.saldo_pagar_mes), opcFormata) }</Card.Meta>
                          </Card>
                        </Grid.Column>
                      )
                    }
                  }.bind(this))
                }
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        }
        
  
        <Accordion style = {{ paddingBottom: '5px', paddingTop: '0px', display: this.state.loader ? 'none': ''}}>
          {
            Fornecedores.map(function(fornecedor,index){
              return(
                <Accordion style = {{ paddingBottom: '5px', paddingTop: '0px', display: this.state.loader ? 'none': ''}}>
                  <Accordion.Title active = {activeIndex === index}
                                   index = {index}
                                   onClick = {this.handleClick}>
                    <Icon name = 'dropdown' />
                    {fornecedor}
                  </Accordion.Title>
                  <Accordion.Content active = {activeIndex === index}>
                    
                    <Accordion.Title active = {activeIndexContrato === index}
                                     index = {index}
                                     onClick = {this.handleClickContrato}
                                     style = {{paddingLeft: '10px'}}>
                      <Icon name = 'dropdown' />
                        GERAL
                    </Accordion.Title>
                    <Accordion.Content active = {activeIndexContrato === index}>
                      <Grid container columns={4}>
                        <Grid.Row>
                        {
                          TotalGeralFornecedorFiltrado.map(function(total, index){
                            if (total.agrupador === fornecedor && total.identificador_mes === '') {
                              return(
                                <Grid.Column style = {{textAlign: 'right'}} >
                                  <Card style={{boxShadow: 'none'}} onClick =  { total.titulo !== 'Geral' ? () => this.atualizaDados(total) : '' }>
                                    <Card.Meta style={{fontWeight: 'bold'}}>{total.titulo}</Card.Meta>
                                    <Card.Meta style={{color: 'black', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_contrato), opcFormata) }</Card.Meta>
                                    <Card.Meta style={{color: 'green', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_pago_contrato), opcFormata) }</Card.Meta>
                                    <Card.Meta style={{color: 'red', fontSize: '10px'}}>{ formatDecimal(parseInt(total.saldo_pagar), opcFormata) }</Card.Meta>
                                  </Card>
                                </Grid.Column>
                              )
                            }
                          }.bind(this))
                        }
                        </Grid.Row>
                      </Grid>

                      <Grid container columns={4}>
                        <Grid.Row>
                        {
                          TotalGeralFornecedorFiltrado.map(function(total, index){
                            if (total.agrupador === fornecedor && total.identificador_mes !== '') {
                              return(
                                <Grid.Column style = {{textAlign: 'right'}} >
                                  <Card style={{boxShadow: 'none'}}>
                                    <Card.Meta style={{fontWeight: 'bold'}}>{total.subtitulo +" "+ total.titulo}</Card.Meta>
                                    <Card.Meta style={{color: 'black', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_contrato), opcFormata) }</Card.Meta>
                                    <Card.Meta style={{color: 'green', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_pago_contrato), opcFormata) }</Card.Meta>
                                    <Card.Meta style={{color: 'red', fontSize: '10px'}}>{ formatDecimal(parseInt(total.saldo_pagar), opcFormata) }</Card.Meta>
                                  </Card>
                                </Grid.Column>
                              )
                            }
                          })
                        }
                        </Grid.Row>
                      </Grid>
                    </Accordion.Content>

                    {
                      Contratos.map(function(contrato, index){
                        if (contrato.agrupador === fornecedor) {
                          return(
                            <div>
                            <Accordion.Title active = {activeIndexContrato === index+10}
                                             index = {index+10}
                                             onClick = {this.handleClickContrato}
                                             style = {{paddingLeft: '10px'}}>
                              <Icon name = 'dropdown' />
                                {contrato.subagrupador}
                            </Accordion.Title>
                            <Accordion.Content active = {activeIndexContrato === index+10}>
                              <Grid container columns={4}>
                                <Grid.Row>
                                {
                                  TotalGeralContratoFiltrado.map(function(total, index){
                                    if (total.subagrupador === contrato.subagrupador && total.identificador_mes === '') {
                                      return(
                                        <Grid.Column style = {{textAlign: 'right'}} >
                                          <Card style={{boxShadow: 'none'}} onClick =  { total.titulo !== 'Geral' ? () => this.atualizaDados(total) : '' }>
                                            <Card.Meta style={{fontWeight: 'bold'}}>{total.titulo}</Card.Meta>
                                            <Card.Meta style={{color: 'black', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_contrato), opcFormata) }</Card.Meta>
                                            <Card.Meta style={{color: 'green', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_pago_contrato), opcFormata) }</Card.Meta>
                                            <Card.Meta style={{color: 'red', fontSize: '10px'}}>{ formatDecimal(parseInt(total.saldo_pagar), opcFormata) }</Card.Meta>
                                          </Card>
                                        </Grid.Column>
                                      )
                                    }
                                  }.bind(this))
                                }
                                </Grid.Row>
                              </Grid>

                              <Grid container columns={4}>
                                <Grid.Row>
                                {
                                  TotalGeralContratoFiltrado.map(function(total, index){
                                    if (total.subagrupador === contrato.subagrupador && total.identificador_mes !== '') {
                                      return(
                                        <Grid.Column style = {{textAlign: 'right'}} >
                                          <Card style={{boxShadow: 'none'}}>
                                            <Card.Meta style={{fontWeight: 'bold'}}>{total.subtitulo +" "+ total.titulo}</Card.Meta>
                                            <Card.Meta style={{color: 'black', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_contrato), opcFormata) }</Card.Meta>
                                            <Card.Meta style={{color: 'green', fontSize: '10px'}}>{ formatDecimal(parseInt(total.valor_pago_contrato), opcFormata) }</Card.Meta>
                                            <Card.Meta style={{color: 'red', fontSize: '10px'}}>{ formatDecimal(parseInt(total.saldo_pagar), opcFormata) }</Card.Meta>
                                          </Card>
                                        </Grid.Column>
                                      )
                                    }
                                  })
                                }
                                </Grid.Row>
                              </Grid>
                            </Accordion.Content>
                            </div>
                          )
                        }
                      }.bind(this))
                    }


                    


                    
                  </Accordion.Content>
                </Accordion>
              )
            }.bind(this))
          }
        </Accordion>
      </div>
    )
  }
}

export default fluxoCaixaGeral