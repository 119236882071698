import React, { Component } from 'react';
import SubCabecalho from '../genericos/subcabecalho.js';
import { Segment, Grid, Button, Icon, Modal, Header, Form, Table, Message, Dimmer, Loader } from 'semantic-ui-react';
import { getToken } from '../../servicos/validatoken.js';

import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';
import highchartsGantt from "highcharts/modules/gantt";
import HC_exporting from 'highcharts/modules/exporting';


highchartsGantt(Highcharts);
HC_exporting(Highcharts);

const request = require('request');

class cronogramafisicoGeral extends Component {
  constructor(props){
    super(props);
    this.state = {
      teste: '',
      Series:[],
      Options:[],
      editarRemover: false,
      disabled: true,
      exibeModal: false,
      linhaSelecionada: [],
      DadosCronogramaFisico: [],
      exibeMensagenAviso: false,
      exibeMensagenAvisoModal: false,
      mensagenAviso: '',
      loader: false,
      loaderDados: false,
      mensagenLoader: '',
      salvarEdicao: false,
      key_etapa: '',
      desabilitaCampos: true,
      AlturaSegment: '480px',
      modalExclusao: false,
      //VARIAVEIS DE CADASTRO/ALTERAÇÃO NO BANCO DE DADOS NA TABELA
      etapa: '',
      fase_etapa: '',
      inicio: '',
      fim: '',
      sequencia: '',
      status: '',
      percentual_conclusao: '',
      dependencia: '',
      responsavel: '',
      codigo_unidade_fabril: '',
      ListaEtapasCadastradas: [],
      ListaDependenciaCadastradas: []
    }
    
    Highcharts.setOptions({
      lang: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        loading: ['Atualizando o gráfico...aguarde'],
        contextButtonTitle: 'Exportar gráfico',
        decimalPoint: ',',
        thousandsSep: '.',
        downloadJPEG: 'Baixar imagem JPEG',
        downloadPDF: 'Baixar arquivo PDF',
        downloadPNG: 'Baixar imagem PNG',
        downloadSVG: 'Baixar vetor SVG',
        printChart: 'Imprimir gráfico',
        rangeSelectorFrom: 'De',
        rangeSelectorTo: 'Para',
        rangeSelectorZoom: 'Zoom',
        resetZoom: 'Limpar Zoom',
        resetZoomTitle: 'Voltar Zoom para nível 1:1',
      }
    });
    
    this.statusBotoesEditarRemover = this.statusBotoesEditarRemover.bind(this);
    this.controleExibicaoModal = this.controleExibicaoModal.bind(this);
    this.salvar = this.salvar.bind(this);
    this.atualizaCronogramafisico = this.atualizaCronogramafisico.bind(this);
    this.editar = this.editar.bind(this);
    this.atualizaAgrupadores = this.atualizaAgrupadores.bind(this);
    this.imprimir = this.imprimir.bind(this);
    //this.beforePrint = this.beforePrint.bind(this);
  }
  
  componentDidMount(){
    this.atualizaCronogramafisico();
  }
  
  atualizaCronogramafisico() {
    this.setState({
      loader: true, mensagenLoader: 'Atualizando o Gráfico'
    });
    //CONSULTA OS REGISTROS DO CRONOGRAMA FISICO
    let getCronogramafisico = {
      method: 'GET',
      url: `https://inteligenciaindustrial.com/engind/cronogramafisico/${this.props.codigoUnidadeFabril}`,
      headers: {
        'content-type': 'application/json',
        'x-access-token':getToken()
      },
      json: true
    };
    
    request(getCronogramafisico, function (error, response, body){
      if (body.length > 0) {
        let Grafico = [];
        let DadosCronogramaFisico = body;
        DadosCronogramaFisico.forEach(function (value, index) {
          let parente = value.fase_etapa,
              responsavel = value.responsavel,
              dependencia = value.dependencia,
              dataInicio = value.inicio.split("/"),
              dataFim = value.fim.split("/"),

              diaInicio = dataInicio[0],
              mesInicio = dataInicio[1],
              anoInicio = "20" + dataInicio[2],

              diaFim = dataFim[0],
              mesFim = dataFim[1],
              anoFim = "20" + dataFim[2];
          diaInicio = diaInicio * 1;
          mesInicio = mesInicio * 1;
          
          diaFim = diaFim * 1;
          mesFim = mesFim * 1;
          
          let dataAtualizacao = new Date(value.data_atualizacao)
          dataAtualizacao = dataAtualizacao.getDate() + "/" + parseInt(dataAtualizacao.getMonth()+1) + "/" + dataAtualizacao.getFullYear();
          if (value.fase_etapa.length > 0) {
            //Tem Parent
            Grafico.push({
              name: parente,
              data: [{
                name: value.sequencia + " - " + value.etapa,
                id: value.etapa,
                dependency: dependencia,
                parent: parente,
                start: Date.UTC(anoInicio, parseInt(mesInicio)-1, diaInicio),
                end: Date.UTC(anoFim, parseInt(mesFim)-1, diaFim),
                color: '#909090',
                completed: {
                  amount: parseInt(value.percentual_conclusao) / 100
                },
                owner: responsavel,
                alterado: value.nome_usuario,
                dataAtualizado: dataAtualizacao
              }]
            });
          } else {
            //Não tem Parent
            Grafico.push({
              name: value.sequencia + " - " + value.etapa,
              data: [{
                name: value.sequencia + " - " + value.etapa,
                id: value.etapa,
                dependency: dependencia,
                start: Date.UTC(anoInicio, parseInt(mesInicio)-1, diaInicio),
                end: Date.UTC(anoFim, parseInt(mesFim)-1, diaFim),
                color: '#909090',
                completed: {
                  amount: parseInt(value.percentual_conclusao) / 100
                },
                owner: responsavel,
                alterado: value.nome_usuario,
                dataAtualizado: dataAtualizacao
              }]
            });
          }
        });
        
        let ListaEtapasCadastradas = body.map(e => e['etapa'])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => body[e]).map(e => body[e]);
        
        let defined = Highcharts.defined, dateFormat = Highcharts.dateFormat, isObject = Highcharts.isObject,
    reduce = Highcharts.reduce;
        
        let Options = {
                  chart: {
                    type: 'gantt',
                    events: {
                      beforePrint: function () {
                          this.oldhasUserSize = this.hasUserSize;
                          this.resetParams = [this.chartWidth, this.chartHeight, false];
                          this.setSize(1200, 400, true);
                      },
                      afterPrint: function () {
                          this.setSize.apply(this, this.resetParams);
                          this.hasUserSize = this.oldhasUserSize;
                      }
                  },
                    // Edit chart spacing
                    //marginLeft: 0,
                    //spacingLeft: 0,

                    // Explicitly tell the width and height of a chart
                    //width: null,
                    //height: null
                  },
                  navigator: {
                    enabled: true,
                    series: {
                      type: 'gantt',
                    },
                  },
                  plotOptions: {
                    series: {
                      allowPointSelect: true,
                      point: {
                        events: {
                          select: this.statusBotoesEditarRemover,
                          unselect: this.statusBotoesEditarRemover
                        }
                      }
                    }
                  },
                  rangeSelector: {
                    buttons: [{
                      count: 1,
                      type: 'month',
                      text: '1 M'
                    },{
                      count: 3,
                      type: 'month',
                      text: '3 M'
                    }, {
                      count: 6,
                      type: 'month',
                      text: '5 M'
                    }, {
                      count: 1,
                      type: 'year',
                      text: '1 A'
                    },{
                      type: 'all',
                      text: 'Todos'
                    }],
                    enabled: true,
                    selected: 5
                  },
                  series: Grafico,
                  tooltip: {
                    pointFormatter: function () {
                      var point = this,
                        format = '%e. %b',
                        options = point.options,
                        completed = options.completed,
                        amount = isObject(completed) ? completed.amount : completed,
                        status = ((amount || 0) * 100) + '%',
                        lines;

                      lines = [{
                        value: point.name,
                        style: 'font-weight: bold;'
                      }, {
                        title: 'Início',
                        value: dateFormat(format, point.start)
                      }, {
                        visible: !options.milestone,
                        title: 'Fim',
                        value: dateFormat(format, point.end)
                      }, {
                        title: 'Concluído',
                        value: status
                      }, {
                        title: 'Responsável',
                        value: options.owner || 'Não Atribuido'
                      }, {
                        title: "Atualizado em",
                        value: options.dataAtualizado
                        
                      }, {
                        title: 'Atualizado por',
                        value: options.alterado
                      }];

                      return reduce(lines, function (str, line) {
                        var s = '',
                          style = (
                            defined(line.style) ? line.style : 'font-size: 10px;'
                          );
                        if (line.visible !== false) {
                          s = (
                            '<span style="' + style + '">' +
                            (defined(line.title) ? line.title + ': ' : '') +
                            (defined(line.value) ? line.value : '') +
                            '</span><br/>'
                          );
                        }
                        return str + s;
                      }, '');
                    }
                  },
                  exporting: {
                    enabled: false, 
                  }
                }
        
        this.setState({
          Series: Grafico, Options: Options, DadosCronogramaFisico: DadosCronogramaFisico, loader: false, ListaEtapasCadastradas: ListaEtapasCadastradas
        });
      } else {
        this.setState({ loader: false, Series: [], Options: [], DadosCronogramaFisico: [], 
                        mensagenAviso: "Não foi salvo nenhum registro de cronograma físico para está unidade."});
      }
    }.bind(this));
  }
  
  statusBotoesEditarRemover(opc) {
    this.setState({
      exibeMensagenAviso: false
    });
    if (opc.accumulate === false && opc.type === 'unselect') {
      this.setState({ disabled: true });
    }
    if (opc.accumulate === false && opc.type === 'select') {
      this.setState({ disabled: false, linhaSelecionada: opc.target.options });
    }
  }
  
  controleExibicaoModal(opc){
    let { exibeModal, DadosCronogramaFisico } = this.state;
    this.setState({
      exibeModal: exibeModal === false ? true : false,
      opcaoModal: opc
    });
    if (exibeModal) {
      this.setState({
        key_etapa: '',
        etapa: '',
        fase_etapa: '',
        inicio: '',
        fim: '',
        sequencia: '',
        status: '',
        percentual_conclusao: '',
        dependencia: '',
        responsavel: '',
        codigo_unidade_fabril: '',
        exibeMensagenAviso: false,
        exibeMensagenAvisoModal: false
      });
      this.atualizaCronogramafisico();
    }
  }
  
  formataTexto(e, {value,name}) {
    this.setState({
      exibeMensagenAvisoModal: false,
      [name]: value.toUpperCase()
    });
    
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    this.setState(
      {exibeMensagenAvisoModal: false,
       [name]: value.toUpperCase()},
      () => input.setSelectionRange(start, end)
    );
  }
  
  formataTextoSelect(e, {value, name}) {
    this.setState({
      exibeMensagenAvisoModal: false,
      [name]: value.toUpperCase()
    });
  }
  
  validaCampoData(data){
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    if (data === "--" || data.length === 8 && data.match(expre) !== null) {
      return true
    } else {
      return false
    }
  }

  
  salvar() {
    this.setState({
      loader: true, mensagenLoader: 'Salvando os dados', loaderModal: true
    });
    let expre = /(([0-2]{1}[0-9]{1}|3[0-1]{1})\/(0[0-9]{1}|1[0-2]{1})\/[0-9]{2})/g;
    let { etapa, fase_etapa, inicio, fim, sequencia, status, percentual_conclusao,
          dependencia, responsavel, codigo_unidade_fabril } = this.state;
    codigo_unidade_fabril = this.props.codigoUnidadeFabril;
    if (etapa.length < 2 && fase_etapa.length < 2) {
      this.setState({
          loader: false,
          exibeMensagenAvisoModal: true,
          mensagenAviso: 'Preencha o campo Etapa ou Fase Etapa corretamente.',
          loaderModal: false
        });
    } else {
      if (inicio.length < 2 || fim.length < 2 || sequencia.length === 0 || status.length < 2 ||
          percentual_conclusao.length === 0) {
        this.setState({
          loader: false,
          exibeMensagenAvisoModal: true,
          mensagenAviso: 'Preencha os campos corretamente.',
          loaderModal: false
        });
      } else {
        if (this.validaCampoData(inicio) && this.validaCampoData(fim)) {
          let postCronogramafisico = {
            method: 'POST',
            url: 'https://inteligenciaindustrial.com/engind/cronogramafisico',
            headers: {
              'content-type': 'application/json',
              'x-access-token':getToken()
            },
            body: {'etapa': etapa, 'fase_etapa': fase_etapa, 'inicio': inicio, 'fim': fim, 'sequencia': sequencia,
                   'status': status, 'percentual_conclusao': percentual_conclusao, 'dependencia': dependencia,
                   'responsavel': responsavel, 'codigo_unidade_fabril': codigo_unidade_fabril, 'key_usuario': this.props.usuario[0].key },
            json: true
          };
          if (this.state.opcaoModal === 'editar') {
            let putCronogramafisico = {
              method: 'PUT',
              url: `https://inteligenciaindustrial.com/engind/cronogramafisico`,
              headers: {
                'content-type': 'application/json',
                'x-access-token':getToken()
              },
              body: {'key': this.state.key_etapa ,'etapa': etapa, 'fase_etapa': fase_etapa, 'inicio': inicio, 'fim': fim, 'sequencia': sequencia,
                   'status': status, 'percentual_conclusao': percentual_conclusao, 'dependencia': dependencia,
                   'responsavel': responsavel, 'codigo_unidade_fabril': codigo_unidade_fabril, 'key_usuario': this.props.usuario[0].key},
              json: true
            };

            request(putCronogramafisico, function (error, response, body) {
              this.setState({
                loader: false, loaderModal: false
              });
              this.atualizaAgrupadores(body[0]);
              this.atualizaCronogramafisico();
              this.controleExibicaoModal();
            }.bind(this));
          } else {
            request(postCronogramafisico, function (error, response, body) {
              if (body.mensagem !== undefined || body.mensagem !== 'undefined') {
                this.atualizaAgrupadores(body);
                this.atualizaCronogramafisico();
                this.setState({
                  etapa: '', dependencia: '', responsavel: '', inicio: '', fim: '', sequencia: '',
                  percentual_conclusao: '', status: '', loader: false, loaderModal: false
                });
              } else {
                this.setState({
                  exibeMensagenAvisoModal:true, mensagenAviso: 'Erro ao realizar o cadastro', loader: false, loaderModal: false
                });
              }
            }.bind(this));
          }

        } else {
          this.setState({
            exibeMensagenAvisoModal:true, mensagenAviso: 'Digite os campos de datas corretamente', loader: false, loaderModal: false
          });
        }
      }
    }
  }
  
  removerRegistro() {
    let { linhaSelecionada, Series, DadosCronogramaFisico } = this.state;
    let RegistroRemover = [];
    RegistroRemover = DadosCronogramaFisico.filter(function (value, index){
      return value.fase_etapa === linhaSelecionada.id
    });
    if (RegistroRemover.length > 0) {
      this.setState({
        loader: false,
        exibeMensagenAviso: true,
        modalExclusao: false,
        mensagenAviso: 'Não é possível excluir uma etapa que tem sub etapas relacionadas.'
      });
    } else {
      let DadosExcluir = DadosCronogramaFisico.filter(function (value, index){
        return value.etapa === linhaSelecionada.id
      });
      if (DadosExcluir.length === 1) {
        let putCronogramafisico = {
          method: 'PUT',
          url: `https://inteligenciaindustrial.com/engind/cronogramafisico`,
          headers: {
            'content-type': 'application/json',
            'x-access-token':getToken()
          },
          body: { 
            key: DadosExcluir[0].key, D_E_L_E_T_: "*", key_usuario: this.props.usuario[0].key
          },
          json: true
        };
        
        request(putCronogramafisico, function (error, response, body) {
          if(body.length > 0) {
            let EtapasAgrupador = DadosCronogramaFisico.filter(function (value){
              return value.fase_etapa === body[0].fase_etapa && value.key !== body[0].key
            });
            
            if(EtapasAgrupador.length > 0) {
              let menorDataInicio = "", maiorDataFim = "";

              EtapasAgrupador.forEach(function(value){
                let inicio = value.inicio.split("/"),
                    fim = value.fim.split("/");

                inicio = new Date("20" + inicio[2], (parseInt(inicio[1] ) -1) * 1, parseInt(inicio[0]) * 1);
                fim = new Date("20" + fim[2], (parseInt(fim[1]) -1) * 1, parseInt(fim[0]) * 1);

                if (menorDataInicio !== "") {
                  if (inicio < menorDataInicio) {
                    menorDataInicio = inicio
                  }
                } else {
                  menorDataInicio = inicio
                }

                if (maiorDataFim !== "") {
                  if (fim > maiorDataFim) {
                    maiorDataFim = fim
                  }
                } else {
                  maiorDataFim = fim
                }
              });
              
              let dia = "",
                  mes = "",
                  ano = "";
              
              //ENCONTRA A MENOR DATA
              dia = menorDataInicio.getDate();
              mes = menorDataInicio.getMonth() + 1;
              ano = menorDataInicio.getFullYear();
              
              dia = dia.toString().length > 1 ? dia : "0" + dia;
              mes = mes.toString().length > 1 ? mes : "0" + mes;
              ano = ano.toString().substring(ano.toString().length,2);
              
              menorDataInicio = dia + "/" + mes +"/"+ ano;
              
              dia = maiorDataFim.getDate();
              mes = maiorDataFim.getMonth() + 1;
              ano = maiorDataFim.getFullYear();
              
              dia = dia.toString().length > 1 ? dia : "0" + dia;
              mes = mes.toString().length > 1 ? mes : "0" + mes;
              ano = ano.toString().substring(ano.toString().length,2);
              
              maiorDataFim = dia + "/" + mes +"/" + ano;
              
              
              let etapaMenorData = EtapasAgrupador.filter(function (value) {
                return value.inicio === menorDataInicio
              });
              
              let etapaMaiorData = EtapasAgrupador.filter(function (value) {
                return value.fim === maiorDataFim
              })            
              
              this.setState({ disabled: true, modalExclusao: false });
              this.atualizaAgrupadores(etapaMenorData[0]);
              this.atualizaAgrupadores(etapaMaiorData[0]);
              
            } else {
              this.setState({ disabled: true, modalExclusao: false });
              this.atualizaCronogramafisico();
            }
          } else {
            this.setState({ disabled: true, modalExclusao: false });
            this.atualizaCronogramafisico();
          }
          
        }.bind(this));
      }
    }
  }
  
  editar(){
    let { linhaSelecionada, Series, DadosCronogramaFisico} = this.state;
    let EtapaEditar = [];
    if (!isNaN(parseInt(linhaSelecionada.id)) && isFinite(linhaSelecionada.id)) {
      EtapaEditar = DadosCronogramaFisico.filter(function (value, index){
        return value.key === linhaSelecionada.id
      })
    } else {
      EtapaEditar = DadosCronogramaFisico.filter(function (value, index){
        return value.etapa === linhaSelecionada.id
      });
    }
    if (EtapaEditar.length > 1) {
      EtapaEditar = EtapaEditar.filter(function (value, index){
        return value.fase_etapa.length === 0
      })
    }
    
    this.setState({
      key_etapa: EtapaEditar[0].key,
      etapa: EtapaEditar[0].etapa,
      fase_etapa: EtapaEditar[0].fase_etapa,
      inicio: EtapaEditar[0].inicio,
      fim: EtapaEditar[0].fim,
      sequencia: EtapaEditar[0].sequencia,
      status: EtapaEditar[0].status,
      percentual_conclusao: EtapaEditar[0].percentual_conclusao,
      dependencia: EtapaEditar[0].dependencia,
      responsavel: EtapaEditar[0].responsavel,
      codigo_unidade_fabril: EtapaEditar[0].codigo_unidade_fabril,
      salvarEdicao: true,
      disabled: true
    });
    this.controleExibicaoModal('editar');
  }
  
  //////////////////////////////////
  atualizaAgrupadores(EtapaRecebida){
    let keyUsuario = this.props.usuario[0].key;
    //CONSULTA OS REGISTROS DO CRONOGRAMA FISICO
    let getCronogramafisico = {
      method: 'GET',
      url: `https://inteligenciaindustrial.com/engind/cronogramafisico/${this.props.codigoUnidadeFabril}`,
      headers: {
        'content-type': 'application/json',
        'x-access-token':getToken()
      },
      json: true
    };
    
    request(getCronogramafisico, function (error, response, body){
      if (body.length > 0) {
        let DadosCronogramaFisico = body;
        //-----------------------------------------------------------
        //VERIFICA SE FOI ADICICIONADO OU ALTERADO ALGUMA ETAPA QUE TEM RELAÇÃO COM ALGUM AGRUPADOR.
        if (EtapaRecebida.fase_etapa.length > 0) {
          let ProximaEtapa = [], nomeProximaEtapa = '';
          do {
            let EtapaCorrente = ProximaEtapa.length > 0 ? ProximaEtapa[0] : EtapaRecebida;
            let AgrupadorCorrente = DadosCronogramaFisico.filter(function (value) {
              return value.etapa === EtapaCorrente.fase_etapa
            });
            //--------------------------------------------------

            if (AgrupadorCorrente.length > 0) {
              let diaIncio = '', mesInicio = '', anoInicio = '';
              let diaFim = '', mesFim = '', anoFim = '';

              //DATA INICIO E FIM ETAPA
              let dataInicioEtapa = EtapaCorrente.inicio.split("/");
              let dataFimEtapa = EtapaCorrente.fim.split("/");

              diaIncio = dataInicioEtapa[0] * 1;
              mesInicio = dataInicioEtapa[1] * 1;
              anoInicio = "20" + dataInicioEtapa[2];

              diaFim = dataFimEtapa[0] * 1;
              mesFim = dataFimEtapa[1] * 1;
              anoFim = "20" + dataFimEtapa[2];

              let dataInicioEtapaFormatada = new Date(anoInicio, parseInt(mesInicio) -1, diaIncio);
              let dataFimEtapaFormatada = new Date(anoFim, parseInt(mesFim) -1, diaFim);

              //DATA INICIO E FIM AGRUPADOR
              let dataInicioAgrupador = AgrupadorCorrente[0].inicio.split("/");
              let dataFimAgrupador = AgrupadorCorrente[0].fim.split("/");

              diaIncio = dataInicioAgrupador[0] * 1;
              mesInicio = dataInicioAgrupador[1] * 1;
              anoInicio = "20" + dataInicioAgrupador[2];

              diaFim = dataFimAgrupador[0] * 1;
              mesFim = dataFimAgrupador[1] * 1;
              anoFim = "20" + dataFimAgrupador[2];

              let dataInicioAgrupadorFormatada = new Date(anoInicio, parseInt(mesInicio) -1, diaIncio);
              let dataFimAgrupadorFormatada = new Date(anoFim, parseInt(mesFim) -1, diaFim);

              //VERIFICAÇÃO DO % DE CONCLUSÃO DO AGRUPADOR
              let TotalDias = 0, percentualAgrupador = 0;

              let EtapasAgrupador = DadosCronogramaFisico.filter(function (value) {
                return value.fase_etapa === AgrupadorCorrente[0].etapa
              });
              

              //VERIFICA E DEFINE A QUANTIDADE DE DIAS DE CADA ETAPA
              EtapasAgrupador.forEach(function (value) {
                let dataInicio = value.inicio.split("/");
                dataInicio = new Date("20" + dataInicio[2], parseInt(dataInicio[1] * 1) -1, dataInicio[0] * 1);
                let dataFim = value.fim.split("/");
                dataFim = new Date("20" + dataFim[2], parseInt(dataFim[1] * 1) -1, dataFim[0] * 1);
                let tempoDiferenca = Math.abs(dataFim.getTime() - dataInicio.getTime());
                let diferencaDias = Math.ceil(tempoDiferenca / (1000 * 3600 * 24));
                diferencaDias = diferencaDias === 0 ? diferencaDias++ : diferencaDias;
                TotalDias+= diferencaDias
                value.dias = diferencaDias
              });

              //DEFINE O PESO DE CADA ETAPA
              EtapasAgrupador.forEach(function (value) {
                value.peso = value.dias / TotalDias;
                value.percentual_agrupador = (value.dias / TotalDias) * value.percentual_conclusao;
                percentualAgrupador += (value.dias / TotalDias) * value.percentual_conclusao;
              });
              
              percentualAgrupador = parseInt(percentualAgrupador);
              //VERIFICA SE É NECESSARIO ATUALIZAR O PERCENTUAL DE CONCLUSÃO DO AGRUPADOR
              if (AgrupadorCorrente[0].percentual_conclusao !== percentualAgrupador) {
                AgrupadorCorrente[0].percentual_conclusao = percentualAgrupador
                let putCronogramafisico = {
                  method: 'PUT',
                  url: `https://inteligenciaindustrial.com/engind/cronogramafisico`,
                  headers: {
                    'content-type': 'application/json',
                    'x-access-token':getToken()
                  },
                  body: { 'key': AgrupadorCorrente[0].key, 'percentual_conclusao': percentualAgrupador, 'key_usuario': this.props.usuario[0].key },
                  json: true
                };
                request(putCronogramafisico, function (error, response, body) {
                }.bind(this));
              }
              
              
              let menorDataInicio = "", maiorDataFim = "";
              EtapasAgrupador.forEach(function (value) {
                let inicio = value.inicio.split("/"),
                    fim = value.fim.split("/");
                
                inicio = new Date("20" + inicio[2], (parseInt(inicio[1] ) -1) * 1, parseInt(inicio[0]) * 1);
                fim = new Date("20" + fim[2], (parseInt(fim[1]) -1) * 1, parseInt(fim[0]) * 1);
                
                if (menorDataInicio !== "") {
                  if (inicio < menorDataInicio) {
                    menorDataInicio = inicio
                  }
                } else {
                  menorDataInicio = inicio
                }

                if (maiorDataFim !== "") {
                  if (fim > maiorDataFim) {
                    maiorDataFim = fim
                  }
                } else {
                  maiorDataFim = fim
                }
              });
              
              let dia = "",
                  mes = "",
                  ano = "";
              
              if (menorDataInicio !== dataInicioAgrupadorFormatada) {
                //FORMATA A MENOR DATA
                dia = menorDataInicio.getDate();
                mes = menorDataInicio.getMonth() + 1;
                ano = menorDataInicio.getFullYear();
                
                dia = dia.toString().length > 1 ? dia : "0" + dia;
                mes = mes.toString().length > 1 ? mes : "0" + mes;
                ano = ano.toString().substring(ano.toString().length,2);
                
                menorDataInicio = dia + "/" + mes +"/"+ ano;
                
                DadosCronogramaFisico.forEach(function (value) {
                  if (value.key === AgrupadorCorrente[0].key) {
                    value.inicio = menorDataInicio
                    let putCronogramafisico = {
                      method: 'PUT',
                      url: `https://inteligenciaindustrial.com/engind/cronogramafisico`,
                      headers: {
                        'content-type': 'application/json',
                        'x-access-token':getToken()
                      },
                      body: { 'key': AgrupadorCorrente[0].key, 'inicio': menorDataInicio, 'key_usuario': keyUsuario },
                      json: true
                    };
                    request(putCronogramafisico, function (error, response, body) {
                    }.bind(this));
                  }
                });
              }
              
              if (maiorDataFim !== dataFimAgrupadorFormatada) {
                dia = maiorDataFim.getDate();
                mes = maiorDataFim.getMonth() + 1;
                ano = maiorDataFim.getFullYear();
                
                dia = dia.toString().length > 1 ? dia : "0" + dia;
                mes = mes.toString().length > 1 ? mes : "0" + mes;
                ano = ano.toString().substring(ano.toString().length,2);
                
                maiorDataFim = dia + "/" + mes +"/" + ano;
                
                DadosCronogramaFisico.forEach(function (value) {
                  if (value.key === AgrupadorCorrente[0].key) {
                    value.fim = maiorDataFim
                    
                    let putCronogramafisico = {
                      method: 'PUT',
                      url: `https://inteligenciaindustrial.com/engind/cronogramafisico`,
                      headers: {
                        'content-type': 'application/json',
                        'x-access-token':getToken()
                      },
                      body: { 'key': AgrupadorCorrente[0].key, 'fim': maiorDataFim, 'key_usuario': keyUsuario },
                      json: true
                    };
                    request(putCronogramafisico, function (error, response, body) {
                    }.bind(this));
                  }
                })
              }
              //-----------------------------------------------------------------------------------------------
            }

            if (EtapaCorrente.fase_etapa !== "" || EtapaCorrente.fase_etapa !== null) {
              nomeProximaEtapa = EtapaCorrente.fase_etapa
            } else {
              nomeProximaEtapa = '';
            }


            if (nomeProximaEtapa !== '') {
              ProximaEtapa = DadosCronogramaFisico.filter(function (value){
                return value.etapa === nomeProximaEtapa
              });
            } else {
              ProximaEtapa = [];
            }
          } while(ProximaEtapa.length > 0);
          this.atualizaCronogramafisico();

        } else {
          this.atualizaCronogramafisico();
        }
      }
    }.bind(this));    
  }
  
  beforePrint() {
    let AlturaSegment = document.getElementById("Segment");
    let Botoes = document.getElementById("Botoes");
    AlturaSegment.style.height = "100%";
    Botoes.style.display = "none";
  }
  
  afterPrint() {
    let AlturaSegment = document.getElementById("Segment");
    let Botoes = document.getElementById("Botoes");
    AlturaSegment.style.height = "480px";
    Botoes.style.display = "block";
  }
  
  imprimir() {    
    window.onbeforeprint = this.beforePrint();
    window.print();
    window.onafterprint = this.afterPrint();
  }
  
  fechaModalExclusao = () => this.setState({ modalExclusao: false })
  
  
  exibeModalExclusao(e){
    this.setState({ modalExclusao: true })
  }
  
  render(){
    let altura = window.innerHeight-87-70-63;
    altura+= "px";
    let Series = this.state.Series;
    let Options = this.state.Options;
    
    document.getElementsByTagName('body')[0].onresize = function(){
      let alturaElemento = document.getElementById('Segment');
      altura = window.innerHeight-87-70-63;
      altura+= "px";
      alturaElemento.style.height = altura;
    }
    
    const listaStatus = [
      {key:'PLANEJADO',text:'PLANEJADO',value:'PLANEJADO'},
      {key:'EM EXECUÇÃO',text:'EM EXECUÇÃO',value:'EM EXECUÇÃO'},
      {key:'PAUSADO',text:'PAUSADO',value:'PAUSADO'},
      {key:'CANCELADO',text:'CANCELADO',value:'CANCELADO'},
      {key:'FINALIZADO',text:'FINALIZADO',value:'FINALIZADO'}
    ];
    
    var today = new Date(),
        day = 1000 * 60 * 60 * 24;
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    today.setUTCMilliseconds(0);
    today = today.getTime();
    
    return(
      <div>
        <SubCabecalho tituloTela = {"CRONOGRAMA FÍSICO GERAL"}
                      opcaoTela = {"cronogramafisicoGeral"} />
          <Grid id = "Botoes" columns={1} style={{textAlign: 'center', paddingBottom: '20px'}}>
                <Grid.Row>
                  <Grid.Column>
                    <Button color = "orange" onClick = {this.controleExibicaoModal.bind(this, 'salvar')}>
                      <Icon name='add' size='small' />
                      ADICIONAR
                    </Button>

                    <Button color = "orange" onClick = {this.editar} disabled={
                      this.state.disabled === false  ? false: true
                    }>
                      <Icon name='edit' size='small' />
                      EDITAR
                    </Button>

                    <Button color = "orange"  onClick = { this.exibeModalExclusao.bind(this) } disabled={
                      this.state.disabled === false ? false: true
                    }>
                      <Icon name='remove' size='small' />
                      REMOVER
                    </Button>

                    <Button color = "orange"  onClick = { this.imprimir.bind(this) }>
                      <Icon name='print' size='small' style= {{ margin: 'auto' }}/> 
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
          <Segment id = "Segment" style={{ display: 'block', margin: 'auto', height: altura, width:'100%', overflow: 'auto' }}>
            { this.state.exibeMensagenAviso && 
              <Message warning style = {{fontSize: '12px', textAlign: 'center'}}>
                <Message.Header>{this.state.mensagenAviso}</Message.Header>
              </Message>
            }
            <Segment style = {{ fontSize:'10px' }}> 
              { this.state.loader && 
                <Dimmer active inverted>
                  <Loader inverted content={this.state.mensagenLoader} />
                </Dimmer>
              }
              { this.state.Series.length === 0 &&
                <Message warning style = {{fontSize: '12px', textAlign: 'center'}}>
                  <Message.Header>{this.state.mensagenAviso}</Message.Header>
                </Message>
              }
              { this.state.Series.length > 0 &&
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"ganttChart"}
                  options = {Options} />
              }
          </Segment>
      
          <Modal open = {this.state.exibeModal}  onClose = {this.controleExibicaoModal} closeIcon>
            <Header icon='tasks' content='CRONOGRAMA FÍSICO' />
            <Modal.Content>
              { this.state.loaderModal && 
                <Dimmer active inverted>
                  <Loader inverted content={this.state.mensagenLoader} />
                </Dimmer>
              }
              { this.state.exibeMensagenAvisoModal &&
                <Message warning style = {{fontSize: '12px', textAlign: 'center'}}>
                  <Message.Header>{this.state.mensagenAviso}</Message.Header>
                </Message>
              }
              
              <Form>
                
                <Form.Group widths='equal'>   
                  <Form.Input fluid label='Etapa' placeholder='Etapa'  name='etapa' 
                    disabled = {this.state.key_etapa.length === 0 ? false: true} 
                    onChange = {this.formataTexto.bind(this)} value = {this.state.etapa} /> 
                </Form.Group>

                <Form.Group widths='equal'>
                  <Form.Input fluid label='Agrupar Etapa' placeholder='Agrupador Etapa'  name='fase_etapa'
                    onChange={this.formataTexto.bind(this)} value={this.state.fase_etapa} list = 'etapa'/>
                  <Form.Input fluid label='Dependência' placeholder='Dependência' name='dependencia'
                    onChange={this.formataTexto.bind(this)} value={this.state.dependencia} list = 'etapa'/>
                  
                  <datalist id='etapa'>
                    {
                      this.state.ListaEtapasCadastradas.map(function(x){
                        return <option key = {x.key} value = {x.etapa} />
                      })
                    }
                  </datalist>
                </Form.Group>
                
                <Form.Group widths='equal'>
                  <Form.Input fluid label='Responsável' placeholder='Responsável' name='responsavel' 
                    onChange={this.formataTexto.bind(this)} value={this.state.responsavel}/>
                  <Form.Select fluid label='Status' placeholder='Status' name='status'
                    onChange={this.formataTextoSelect.bind(this)} value={this.state.status} options={listaStatus}/>
                </Form.Group>

                <Form.Group widths='equal'>
                  <Form.Input fluid label='Início' placeholder='Início'  name='inicio'
                    onChange={this.formataTexto.bind(this)} value={this.state.inicio}/>
                  <Form.Input fluid label='Fim'  placeholder='Fim'  name='fim'
                    onChange={this.formataTexto.bind(this)} value={this.state.fim}/>
                  <Form.Input fluid label='Sequência' placeholder='Sequência' name='sequencia'
                    onChange={this.formataTexto.bind(this)} value={this.state.sequencia}/>
                  <Form.Input fluid label='% Conclusão' placeholder='% Conclusão' name='percentual_conclusao'
                    onChange={this.formataTexto.bind(this)} value={this.state.percentual_conclusao}/>
                </Form.Group>

                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column width={4}>            
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Button floated='right' style={{backgroundColor:'#eb5c0a',color:'white'}} onClick = {this.salvar}>
                        <Icon name='save outline' /> Salvar
                      </Button>
                      <Button floated='right' style = {{marginRight: '20px'}} onClick={this.controleExibicaoModal} color='grey'>
                        <Icon name='remove' /> Cancelar
                      </Button>
                    </Grid.Column>                      
                  </Grid.Row>
                </Grid>

              </Form>

              <Segment clearing style={{width:'100%',height:'250px', overflowY:'auto'}}>
                <Table celled fixed compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{width:'30%'}}>ETAPA</Table.HeaderCell>                        
                      <Table.HeaderCell style={{width:'30%'}}>ETAPA AGRUPADA</Table.HeaderCell> 
                      <Table.HeaderCell style={{width:'12%'}}>SEQUÊNCIA</Table.HeaderCell> 
                      <Table.HeaderCell style={{width:'10%'}}>INÍCIO</Table.HeaderCell>                        
                      <Table.HeaderCell style={{width:'10%'}}>FIM</Table.HeaderCell>                                                                      
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { this.state.loaderModal &&
                      <Dimmer active inverted>
                        <Loader inverted content='Carregando os dados' />
                      </Dimmer>
                    }
                    {
                      this.state.DadosCronogramaFisico.map(({ key, etapa, fase_etapa, inicio, fim, percentual_conclusao, sequencia }) => (
                        <Table.Row key={key}>
                          <Table.Cell style={{width:'30%'}}>{etapa}</Table.Cell>                        
                          <Table.Cell style={{width:'30%'}}>{fase_etapa}</Table.Cell>
                          <Table.Cell style={{width:'20%'}}>{sequencia}</Table.Cell> 
                          <Table.Cell style={{width:'10%'}}>{inicio}</Table.Cell>                        
                          <Table.Cell style={{width:'10%'}}>{fim}</Table.Cell>                                                                         
                        </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
              </Segment>
            </Modal.Content>
          </Modal>

          <Modal open={this.state.modalExclusao} closeOnEscape={true} closeOnDimmerClick={false} onClose={this.fechaModalExclusao}>
          <Modal.Header>Deletar Etapa</Modal.Header>
          { this.state.loader && 
            <Dimmer active inverted>
              <Loader inverted content='Excluindo Etapa' />
            </Dimmer>
          }
          <Modal.Content>
            <p>VOCÊ TEM CERTEZA QUE DESEJA DELETAR ESSA ETAPA?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.fechaModalExclusao} color = 'grey'>
              NÃO
            </Button>
            <Button
              onClick={this.removerRegistro.bind(this)}
              color = 'orange'
              labelPosition='right'
              icon='checkmark'
              content='SIM'
            />
          </Modal.Actions>
        </Modal>
        </Segment>
      </div>
    );
  }
}

export default cronogramafisicoGeral