import React, { Component } from 'react';
import { getToken } from '../../servicos/validatoken.js';
import PubSub from 'pubsub-js';
import {
  Loader,
  Message,
  Card,
  Button,
  Label,
  Segment,
  Icon,
  Grid,
  Modal,
  Header,
  Container
} from 'semantic-ui-react';
import SubCabecalho from '../genericos/subcabecalho.js';

class ListaProjeto extends Component {
  constructor(props){
    super(props);
    this.state = {
      Loader: false,
      exibeMensagem: '',
      corMensagem: '',
      Projetos: '',
      exibeDescricao: false,
      descricao_projeto: ''
    };
    this.carregaProjetoSelecionado = this.carregaProjetoSelecionado.bind(this);
    this.exibeDescricao = this.exibeDescricao.bind(this);
  }
  
  carregaProjetoSelecionado(key, descricaoProjeto){
    PubSub.publish('idProjeto', { idProjeto:key });
    PubSub.publish('descricao_geral', { descricao_geral:descricaoProjeto });
    PubSub.publish('opcaoComponente', { opcaoComponente:'carregarProjeto' });
  }
  
  componentDidMount(){
    this.setState({ Loader: true });
    const request = require('request'),
          opcoesProjetos = {
            method: 'GET',
            url:'https://inteligenciaindustrial.com/engind/projeto',
            headers: {
              'content-type': 'application/json',
              'x-access-token': getToken()
            },
            json: true
          };
    //
    request(opcoesProjetos, function (error, response, body) {
      if (body !== undefined) {
        if (body.length > 0) {
          const resultadoBody = body;
          PubSub.publish('projeto', { projeto:resultadoBody });
          let Projetos = '';
          if (this.props.idStatus !== null) {
            //Separa por status
            const statusProjeto = this.props.statusProjeto.filter((status) => {
                return status.id === this.props.idStatus;
              });
            Projetos = resultadoBody.filter((info) => {
              return info.codigo_status_projeto === statusProjeto[0].codigo_status_projeto &&
                     info.codigo_unidade_fabril === this.props.codigoUnidadeFabril
            });
          } else {
            //Lista todos
            Projetos = resultadoBody.filter((info) => {
              return info.codigo_unidade_fabril === this.props.codigoUnidadeFabril
            });
          }
          const ListaProjetos = Projetos.map(projeto => {
            let nomeLabel = 'SEM STATUS CADASTRADO', corLabel = 'red';
            if (projeto.codigo_status_projeto) {
              const statusProjeto = this.props.statusProjeto.filter((status) => {
                return status.codigo_status_projeto === projeto.codigo_status_projeto;
              });
              corLabel = statusProjeto[0].cor_status_projeto;
              nomeLabel = statusProjeto[0].descricao_status_projeto;
            } else {
              
            }
              return(
                <Card centered key = { projeto.id } style = {{ width: '100%' }}>
                  <Card.Content>
                    <Card.Header style = {{ fontSize: '16px', width: '70%' }}>{projeto.descricao_projeto}</Card.Header>
                    <Card.Meta>
                      <Label attached = 'top right' size = 'mini' style={{fontSize:'8px'}} color = {corLabel}>
                        {nomeLabel}
                      </Label>
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    { (projeto.descricao_geral !== null && projeto.descricao_geral !== '') && 
                      <Icon color = 'orange' link name = 'info circle' size = 'big' style = {{width:'10%'}}
                            onClick = {() => this.exibeDescricao(projeto) }/>
                    }
                    <Button style={{width: '85%', color:'#ea5d0c', backgroundColor:'white'}} onClick={(e) => this.carregaProjetoSelecionado(projeto.key,
                                                                                  projeto.descricao_projeto, e)}>
                      Ver Detalhes
                      <Icon name = 'sign-in' style = {{paddingLeft: '10px'}}/>
                    </Button>
                  </Card.Content>
                </Card>
              );
          });
          if (ListaProjetos.length > 0) {
            this.setState({Projetos: ListaProjetos, Loader: false })
          } else {
            this.setState({ exibeMensagem: 'Nenhum registro de projeto com esse status.', corMensagem: "orange", 
                          Loader: false});
          }
        }
        if (body.length === 0) {
          this.setState({ exibeMensagem: 'Nenhum registro de projeto encontrado.', corMensagem: "orange", 
                          Loader: false});
        }
        if (body.mensagem) {
          PubSub.publish('tokenInvalido', { tokenInvalido:true });
          PubSub.publish('autenticacao', { validado:false });
        }
      } else {
        console.log("Erro");
        this.setState({ exibeMensagem: "Não foi possivel conectar-se a internet tente novamente", corMensagem: "red",
                        Loader: false });
      }
    }.bind(this));
  }
  
  exibeDescricao(projeto) {
    if (this.state.exibeDescricao) {
      this.setState({
        exibeDescricao: false
      });
    } else {
      let conteudoModal = '';
      conteudoModal = projeto.descricao_geral
      this.setState({
        descricao_projeto: conteudoModal, exibeDescricao: true
      });
      this.setState({
        exibeDescricao: true
      });
    }
  }
  
  render() {
    let statusProjeto = '';
    if (this.props.idStatus !== undefined && this.props.statusProjeto !== undefined && 
        this.props.unidadesFabril !== undefined) {
      let descricaoUnidade = '';
        this.props.unidadesFabril.filter(unidade => {
          if (unidade.id === this.props.idFabrica) {
            descricaoUnidade = unidade.descricao_unidade_fabril
          }
          return 0;
        });
      if (this.props.idStatus === null) {
        statusProjeto = 'TODOS OS PROJETOS - ' + descricaoUnidade;
      } else {
        this.props.statusProjeto.filter(status => {
          if (status.id === this.props.idStatus) {
            statusProjeto = 'PROJETOS ' + status.descricao_status_projeto + ' - ' + descricaoUnidade;
          }
          return 0;
        });
      }
    }
    //console.log(this.props.idStatus);
    return(
      <div>
        <SubCabecalho tituloTela = { statusProjeto } 
                                    opcaoTela = {'listaProjeto'} />
        { this.state.Projetos.length > 0 &&
          <Segment.Group style = {{paddingTop: '0px'}}>
            <Segment.Group horizontal>
              <Card.Group textAlign = 'center' style={{ fontSize:'10px', display: 'block', margin: 'auto', 
                                                        height: '480px', width:'100%', paddingLeft: '20px', 
                                                        paddingRight: '10px', overflow: 'auto'}}>
                { this.state.Projetos }
              </Card.Group>
            </Segment.Group>
          </Segment.Group>
        }
        <Modal closeIcon size = 'mini' open = { this.state.exibeDescricao } onClose = { this.exibeDescricao }>
          <Header color = 'orange' icon='info circle' content='DESCRIÇÃO' />
            <Modal.Content>
              <Container>
                <p>
                  {this.state.descricao_projeto}
                </p>
              </Container>
            </Modal.Content>
        </Modal>
        { this.state.Loader && <Loader active inline='centered'/> }
        {
          this.state.exibeMensagem && 
          <Message color = {this.state.corMensagem}> {this.state.exibeMensagem} </Message>
        }
      </div>
    );
  }
}
export default ListaProjeto;